body.common.category,
body.common.archive{
	header.sub-nav{
		background: #014099;
		h1{
			svg{
			}
		}
		@include media(sp){
			h1{
				svg{
				}
			}
		}
		.sub-nav-btn-wrap{
			.sub-nav-btn{
			}
			@include media(sp){
			}
			p{
			}
			.sub-nav-btn:before,
			.sub-nav-btn:after{
			}
			.sub-nav-btn:before{
			}
			.sub-nav-btn:after{
			}
		}
		.sub-nav-btn-wrap:hover{
		}
		.sub-nav-all{
			.sub-nav-all-wrap{
				.sub-nav-all-block{
					.sub-nav-all-menu-icon{
					}
					.sub-nav-all-menu-icon:before{
					}
					h4{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
							.sub-nav-all-menu-icon{
							}
							.sub-nav-all-menu-icon:before{
							}
						}
					}
					ul{
					}
					li{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
						}
						a:before{
					}
					}
				}
				.sub-nav-all-block:first-child{
				}
				@include media(sp){
					.sub-nav-all-block{
					}
				}
			}
			@include media(sp){
				.sub-nav-all-wrap{
				}
			}
		}
		.sub-nav-all:before{
		}
		@include media(sp){
			.sub-nav-all{
				.sub-nav-all-wrap{
				}
			}
		}
	}
	@include media(sp){
		header.sub-nav{
		}
	}

	#main-area{
		height: 240px;
		background: url(../img/no-img-news-header.jpg) no-repeat right;
		background-size: contain;
		margin-bottom: 50px;
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			width: 40%;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 50%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.7) 100%);
	}
	@include media(sp){
		#main-area{
			height: 50vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
				background-image: url(../img/no-img-news-header-sp.jpg);
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			width: 100%;
			background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
		}
	}

	#primary{
		width: 950px;
		margin: 0 auto 50px auto;
		padding: 40px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
		-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
		box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
		.sec-comn{
			a,
			a:link,
			a:visited,
			a:active{
				@include  flex(wrap,left,space-between);
				opacity: 1;
				filter: alpha(opacity=100);
				margin-bottom: 10px;
			}
			a:hover{
				opacity: 0.8;
				filter: alpha(opacity=80);
			}
			.news-img-wrap{
				width: 200px;
				height: 200px;
				background-size: cover;
			}
			.news-txt{
				font-size: 1.4rem;
				font-weight: 400;
				display: block;
				width: 650px;
				padding: 0 0 0 30px;
				.news-ttl{
					font-size: 1.6rem;
					font-weight: 600;
					margin: 10px 0;
				}
				.date{
					margin: 0;
				}
				p{
					margin: 0;
				}
			}
			.entry-content{
			}
		}
	}
	#page-nav{
		width: 950px;
		margin: 0 auto 50px auto;
		.wp-pagenavi{
			@include  flex(wrap,center,space-between);
			span,
			a{
				padding: 10px 14px;
				margin: 0 4px;
				border: 1px solid #014099;
				color: #014099;
				font-weight: 600;

			}
			a,
			a:link,
			a:visited,
			a:active{
			}
			a:hover{
				border: 1px solid #014099;
				background: #014099;
				color: #fff;
			}
			.current{
				background: #014099;
				color: #fff;
				border: none;
			}
		}
	}

	@include media(sp){
		#primary{
			width: auto;
			.sec-comn{
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					margin-bottom: 30px;
				}
				a:hover{
					opacity: 0.8;
					filter: alpha(opacity=80);
				}
				.news-img-wrap{
					width: 100%;
					background-position: center;
				}
				.news-txt{
					width: auto;
					padding: 10px 0;
					.news-ttl{
						font-size: 1.6rem;
						font-weight: 600;
						margin: 10px 0;
					}
					.date{
						margin: 0;
					}
					p{
						margin: 0;
					}
				}
				.entry-content{
				}
			}
		}
		#page-nav{
			width: auto;
			.wp-pagenavi{
				@include  flex(wrap,center,space-between);
				span,
				a{
					padding: 10px 14px;
					margin: 0 4px;
					border: 1px solid #014099;
					color: #014099;
					font-weight: 600;

				}
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
					border: 1px solid #014099;
					background: #014099;
					color: #fff;
				}
				.current{
					background: #014099;
					color: #fff;
					border: none;
				}
			}
		}
	}
}


@include media(sp){

}

