body.common.page-template-page-default{
	#main-area{
		height: 240px;
		background-image: url(../img/company/company-top-header.jpg);
		background-size: contain;
		margin-bottom: 50px;
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 50%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.7) 100%);
	}
	@include media(sp){
		#main-area{
			height: 50vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
				background-image: url(../img/company/company-top-header-sp.jpg);
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			width: 100%;
			background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
		}
	}
	.corporation-block{
		// width: 860px;
		margin: 110px auto 100px auto;
		// -moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		// -webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		// -ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		// box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);


		width: 960px;
		// margin: 30px auto 70px auto;
		// padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		.corporation-block-inner{
			padding: 0 50px 50px 50px;
		}
		.corporation-block-inner:first-child{
			padding-top: 50px;
		}
		.corporation-block-detail{
			p{
				line-height: 1.8;
			}
		}
		h2{
			margin: 0;
			font-size: 2.2rem;
			font-weight: 600;
			padding: 40px 0 20px 0;
		}
		h2:first-child{
			padding-top: 0;
		}
		h3{
			margin: 0;
			font-size: 2rem;
			font-weight: 600;
			padding: 0 0 18px 0;
			img{
				width: 2.5rem;
				margin-right: 10px;
			}
		}
		h4{
			margin-top: 0;
			font-size: 1.6rem;
		}
		p{
			margin: 0;
			font-size: 1.3rem;
			font-weight: 300;
		}
		img.icon10{
			display: inline;
			width: 70px;
			margin: 0 10px 0 0;
			vertical-align: middle;
		}
		table{
			border-collapse: separate;
			border-spacing: 5px;
			tbody{

			}
			tr{
				th,td{
					font-size: 1.3rem;
					font-weight: normal;
				}
				th{
					width: 20%;
					background: #d4f0f8;
					padding: 20px 5px;
				}
				td{
					width: 80%;
					border: 1px solid #f0f4f9;
					padding: 20px 30px 20px 30px;
				}
			}
			tr:nth-child(even){
				td{
					border: none;
					background: #f0f4f9;
				}
			}
		}
		.product-disc-block-detail{
			padding: 30px 0 20px 0;
			h3{
				margin: 50px 0 0 0;
				padding: 0;
			}
			video{
				width: 520px;
				height: auto;
				display: block;
				margin: 40px auto 0 auto;
			}
			img{
				width: auto;
				height: auto;
				display: block;
				// margin: 20px auto 0 auto;
				margin: 0 auto 0 auto;
			}
			a,
			a:link,
			a:visited,
			a:active{
			}
			a:hover{
				@include hover;
			}
		}
	}
	@include media(sp){
		.corporation-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.corporation-block-inner{
				padding: 0;
			}
			.corporation-block-inner:first-child{
				padding: 0;
			}
			h2{
				font-size: 2rem;
				padding: 30px 0 10px 0;
			}
			h2:first-child{
				padding-top: 0;
			}
			h3{
				font-size: 1.8rem;
				img{
				}
			}
			table{
				border-collapse: initial;
				border-spacing: 0;
				display: block;
				tbody{
					display: block;
				}
				tr{
					display: block;
					th,td{
						display: block;
						font-size: 1.3rem;
						font-weight: normal;
						text-align: left;
						margin: 6px 0;
					}
					th{
						width: 100%;
						background: #d4f0f8;
						padding: 14px 20px 14px 20px;
						margin: 20px 0 6px 0;
					}
					td{
						width: 100%;
						border: 1px solid #f0f4f9;
						padding: 14px 20px 14px 20px;
					}
				}
				tr:nth-child(even){
					td{
						border: 1px solid #f0f4f9;
						background: #fff;
					}
				}
			}
			.product-disc-block-detail{

				video,
				img{
					margin: 20px auto 0 auto;
					width: 100%;
				}
			}
		}
	}
	.corporation-block.faq{
		dl{
			dt,dd{
				padding: 0;
				margin: 0;
				position: relative;
				line-height: 1.9;
				span{
					position: absolute;
				}
			}
			dt{
				font-size: 1.8rem;
				font-weight: 500;
				padding: 0 0 0 3.4rem;
				span{
					color: #0cbb8c;
					font-size: 3.4rem;
					top: -15px;
					left: 0;
				}
			}
			dd{
				font-size: 1.4rem;
				font-weight: 500;
				padding: 30px 0 20px 3.4rem;
				span{
					font-size: 3rem;
					top: 15px;
					left: 5px;
				}
			}
			a,
			a:link,
			a:visited,
			a:active{
				text-decoration: underline;
			}
			a:hover{
			}
		}
	}
	@include media(sp){
		.corporation-block.faq{
			dl{
				dt,dd{
					span{
					}
				}
				dt{
					span{
					}
				}
				dd{
					padding: 20px 0 16px 3.4rem;
					span{
					}
				}
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
				}
			}
		}
	}
	.sitemap-block{
		width: 960px;
		margin: 110px auto 100px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		@include flex(wrap,left,space-between);
		.sitemap-block-left,
		.sitemap-block-right{
			width: 50%;
			.sitemap-stg1,
			.sitemap-stg2,
			.sitemap-stg3{
				display: block;
				font-size: 1.4rem;
				font-weight: 600;
				position: relative;
			}
			.sitemap-stg1{
				
			}
			.sitemap-stg2{
				padding: 5px 0;
				margin: 0 0 0 1.5rem;
			}
			.sitemap-stg3{
				padding: 5px 0;
				margin: 0 0 0 3rem;
			}
			.sitemap-stg2:before{
				content: "";
				width: 3px;
				height: 1.6rem;
				display: block;
				background: #004099;
				position: absolute;
				left: -10px;
				top: 50%;
				margin-top: -7px;
			}
			.sitemap-stg3:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				left: -20px;
				top: 50%;
				margin-top: -4px;
			}
		}
		.sitemap-block-left{
			.sitemap-stg1,
			.sitemap-stg2,
			.sitemap-stg3{
			}
			.sitemap-stg1{
				
			}
			.sitemap-stg2{
				
			}
			.sitemap-stg3{
				
			}
		}
		.sitemap-block-right{
			.sitemap-stg1,
			.sitemap-stg2,
			.sitemap-stg3{
			}
			.sitemap-stg1{
				
			}
			.sitemap-stg2{
				
			}
			.sitemap-stg3{
				
			}
		}
	}
	@include media(sp){
		.sitemap-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			display: block;
			.sitemap-block-left,
			.sitemap-block-right{
				width: auto;
				.sitemap-stg1,
				.sitemap-stg2,
				.sitemap-stg3{
				}
				.sitemap-stg1{
					
				}
				.sitemap-stg2{
					padding: 10px 0;
				}
				.sitemap-stg3{
					padding: 10px 0;
				}
				.sitemap-stg2:before{
					content: "";
					width: 4px;
					height: 1.6rem;
					display: block;
					background: #004099;
					position: absolute;
					left: -10px;
					top: 50%;
					margin-top: -7px;
				}
				.sitemap-stg3:before{
					width: 4px;
					height: 4px;
					left: -16px;
					top: 50%;
					margin-top: -2px;
				}
			}
			.sitemap-block-left{
				.sitemap-stg1,
				.sitemap-stg2,
				.sitemap-stg3{
				}
				.sitemap-stg1{
					
				}
				.sitemap-stg2{
					
				}
				.sitemap-stg3{
					
				}
			}
			.sitemap-block-right{
				.sitemap-stg1,
				.sitemap-stg2,
				.sitemap-stg3{
				}
				.sitemap-stg1{
					
				}
				.sitemap-stg2{
					
				}
				.sitemap-stg3{
					
				}
			}
		}
	}
	.default-page-block,
	.seminer-block,
	.gallery-block{
		width: 960px;
		margin: 110px auto 100px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		.seminer-block-txt{
			p{
				line-height: 1.8;
			}
		}
		.seminer-block-2cul{
			@include flex(wrap,left,space-between);
			.seminer-block-2cul-block{
				width: 50%;
				padding: 2px;
			}
		}
		.seminer-block-3cul{
			@include flex(wrap,left,space-between);
			img{
				width: 33.3333333333%;
				padding: 2px;
			}
		}
		.seminer-block-4cul{
			@include flex(wrap,left,space-between);
			img{
				width: 25%;
				padding: 2px;
			}
		}
		h2{
			margin: 0;
			font-size: 2.2rem;
			font-weight: 600;
			padding: 40px 0 20px 0;
		}
		h2:first-child{
			padding-top: 0;
		}
		.gallery-block-wrap{
			@include flex(wrap,left,space-between);
			a,
			a:link,
			a:visited,
			a:active{
				display: block;
				width: 280px;
				height: 200px;
				margin: 4px;
				background-position: center;
				background-size: cover;
			}
			a:hover{
				.fas{
				}
			}
		}
	}
	@include media(sp){
		.default-page-block,
		.seminer-block,
		.gallery-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.seminer-block-txt{
				p{
					line-height: 1.8;
				}
			}
			.seminer-block-2cul{
				display: block;
				.seminer-block-2cul-block{
					width: 100%;
					margin-bottom: 10px;
				}
			}
			.seminer-block-3cul{
				img{
					width: 50%;
				}
			}
			.seminer-block-4cul{
				img{
					width: 50%;
				}
			}
			h2{
				margin: 0;
				font-size: 2.2rem;
				font-weight: 600;
				padding: 40px 0 20px 0;
			}
			h2:first-child{
				padding-top: 0;
			}
			.gallery-block-wrap{
				@include flex(wrap,center,space-between);
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					width: 49%;
					height: 100px;
					margin: 0.5%;
					background-position: center;
					background-size: cover;
				}
				a:hover{
					.fas{
					}
				}
			}
			
		}
	}
}




@include media(sp){

}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

