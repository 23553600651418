
footer#colophon{
	min-width: 1200px;
	#footer-menu-backtotop{
		background: #f0f0f0;
		position: relative;
		.footer-adrs{
			margin: 0;
			@include flex(wrap,center,space-between);
			-ms-flex-align: center;
			align-items: center;
			span{
				padding: 0 0 0 40px;
				font-size: 1.3rem;
				font-weight: normal;
			}
			@include media(sp){
				span{
					display: block;
					padding: 10px 0 0 0;
				}
			}
		}
		@include media(sp){
			.footer-adrs{
				line-height: 1;
				text-align: center;
				display: block;
				a,
				a:link,
				a:visited,
				a:active{
					img{
						width: 80%;
					}
				}
				a:hover{
				}
			}
		}
	}
	#footer-menu-block-wrap{
		padding: 40px 110px 0 40px;
	}
	@include media(sp){
		#footer-menu-block-wrap{
			padding: 30px 80px 0 10px;
		}
	}
	#footer-menu-backtotop{
		#backto-top{
			position: fixed;
			z-index: 900;
			right: 0;
			bottom: 0;
			background: transparent;
			width: 110px;
			height: 230px;
			cursor: pointer;
			opacity: 1;
			filter: alpha(opacity=100);
			@include trans;
			p{
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				margin: 0;
				color: #014099;
				font-size: 1.4rem;
				font-family: 'Asap', sans-serif;
				margin: 0 auto;
				padding: 40px 0 0 0;
				position: relative;
			}
			.backto-icon{
				display: block;
				height: 20px;
				width: 1px;
				background: #d2a100;
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -1px;
			}
			.backto-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				top: 15px;
				right: 50%;
				margin-right: -3px;
				@include trans;
			}
		}
		@include media(sp){
			#backto-top{
				width: 70px;
			}
		}
		#backto-top:after{
			display: none;
		}
		#backto-top:hover{
			.backto-icon{
			}
			.backto-icon:before{
				top: -5px;
			}
		}
	}
	#footer-menu-backtotop.show{
		#backto-top{
			opacity: 0;
			filter: alpha(opacity=0);
			p{
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				margin: 0;
				color: #014099;
				font-size: 1.4rem;
				font-family: 'Asap', sans-serif;
				margin: 0 auto;
				padding: 40px 0 0 0;
				position: relative;
			}
			.backto-icon{
				display: block;
				height: 20px;
				width: 1px;
				background: #d2a100;
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -1px;
			}
			.backto-icon:before{
			}
		}
	}
	#footer-menu-backtotop.show.active{
		#backto-top{
			background: #fff;
			height: 100%;
			position: absolute;
			bottom: initial;
			top: 0;
			opacity: 1;
			filter: alpha(opacity=100);
			p{
			}
			.backto-icon{
			}
			.backto-icon:before{
			}
		}
		@include media(sp){
			#backto-top{
				width: 70px;
			}
		}
		#backto-top:after{
			content: "";
			height: 131px;
			width: 100%;
			display: block;
			background-color: #292929;
			position: absolute;
			left: 0;
			bottom: -1px;
		}
	}
	.footer-menu-wrap{
		// width: 1040px;
		padding: 50px 0 50px 100px;
		margin: 0 auto;
		// @include flex(wrap,space-between,space-between);
	}
	@include media(sp){
		.footer-menu-wrap{
			// width: 1040px;
			padding: 30px 0 30px 0;
			margin: 0 auto;
			// @include flex(wrap,space-between,space-between);
		}
	}
	.footer-menu-left,
	.footer-menu-center,
	.footer-menu-right{
		@include flex(wrap,center,space-between);
		.footer-menu{
			margin: 0 60px 50px 0;
			width: 250px;
		}
		@include media(sp){
			.footer-menu{
				padding: 10px 0 0 0;
				margin: 0;
				width: 100%;
				position: relative;
			}
			.footer-menu:before{
				content: "";
				height: 18px;
				width: 2px;
				display: block;
				background-color: #dfdfdf;
				position: absolute;
				right: 20px;
				top: 17px;
				margin: 0 0 0 0;
			}
			.footer-menu:after{
				content: "";
				height: 2px;
				width: 18px;
				display: block;
				background-color: #dfdfdf;
				position: absolute;
				right: 12px;
				top: 25px;
				margin: 0 0 0 0;
			}
		}
	}
	@include media(sp){
		display: block;
	}
	.footer-menu-left{
	}
	.footer-menu-center{
	}
	.footer-menu{
		h3{
			margin: 0;
			color: #292929;
			font-family: 'Asap', sans-serif;
			font-size: 1.8rem;
			padding: 0 0 20px 0;
			margin: 0 0 20px 0;
			border-bottom: 3px solid #dfdfdf;
		}
		@include media(sp){
			h3{
				padding: 0 0 10px 0;
				margin: 0 0 0 0;
			}
		}
		.footer-menu-block{
			list-style: none;
			padding: 0;
			li{
				padding-top: 20px;
				a,
				a:link,
				a:visited,
				a:active{
					color: #292929;
					display: block;
					font-family: 'Asap', sans-serif;
					font-size: 1.2rem;
					position: relative;
					padding: 0 0 0 40px;
					.footer-menu-icon{
						display: block;
						height: 1px;
						width: 20px;
						background: #d2a100;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -1px;
					}
					.footer-menu-icon:before{
						content: "";
						width: 7px;
						height: 7px;
						display: block;
						background: #d2a100;
						border-radius: 5px;
						position: absolute;
						right: -7px;
						top: 50%;
						margin-top: -3px;
						@include trans;
					}
				}
				a:hover{
					opacity: 0.8;
					filter: alpha(opacity=80);
					.footer-menu-icon{
					}
					.footer-menu-icon:before{
						right: 20px;
					}
				}
			}
		}
	}
	@include media(sp){
		.footer-menu{
			.footer-menu-block{
				display: none;
			}
		}
		.footer-menu.open{
			.footer-menu-block{
				display: block;
			}
		}
		// .footer-menu.open:before{
		// 	right: 20px;
		// 	top: 20px;
		// 	margin: -8px 0 0 0;
		// }
		// .footer-menu.open:after{
		// 	right: 12px;
		// 	top: 20px;
		// 	margin: 0 0 0 0;
		// }
	}
	#cpoy{
		background: #292929;
		color: #fff;
		padding: 30px 40px;
		@include flex(wrap,space-between,space-between);
		-ms-flex-align: center;
		align-items: center;
		.copy-comp-name{
			padding: 0;
			margin: 0;
		}
		.copyright{
			padding: 0;
			margin: 0;
		}
	}
	@include media(sp){
		#cpoy{
			padding: 20px 10px;
		}
	}
}


body.page-template-page-product-under-page,
body.page-template-page-product-smile-connect{
	footer#colophon{
		#cpoy{
			padding: 30px 40px 100px 40px;
			.copy-comp-name{
			}
			.copyright{
			}
		}
		@include media(sp){
			#cpoy{
				padding: 30px 40px 130px 40px;
				.copy-comp-name{
				}
				.copyright{
				}
			}
		}
	}
}

@include media(sp){
	footer#colophon{
		min-width: auto;
	}
}

#wh-paper{
	position: fixed;
	bottom: 0px;
	left: 30px;
	z-index: 9999;
	width: 150px;
	padding: 6px 6px 6px 6px;
	border: 1px solid #ddd;
	border-bottom: none;
	background: #fff;
	a,
	a:link,
	a:visited,
	a:active{
		display: block;
		line-height: 0;
	}
	a:hover{
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
}

@include media(sp){

	#wh-paper{
		left: 0;
		width: 50%!important;
		a,
		a:link,
		a:visited,
		a:active{
		}
		a:hover{
		}
	}


}

// tab
@media all and (min-width: $brakeWSP) {
}
// tab
// PC
@media all and (min-width: $brakeWPC) {
}
// PC