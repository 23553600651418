body.common.page-template-page-support{

	header.sub-nav{
		background: #cd1a59;
	}

	#main-area{
		height: 240px;
		background-image: url(../img/company/company-top-header.jpg);
		background-repeat: no-repeat;
		background-position: center right;
		background-size: cover;
		#main-area-wrap{
			background-image: auto;
		}
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgb(255, 255, 255) 40%,rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
	}
	@include media(sp){
		#main-area{
			height: 70vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px 50px 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
					margin: 0;
				}
			}
		}
		#main-area:after{
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			overflow:hidden;
			width: 100%;
			background-image: linear-gradient(0deg, white 30%, rgba(255, 255, 255, 0) 100%);
		}
	}

	#page-nav{
		border-bottom: 1px solid #014099;
		ul{
			@include flex(wrap,center,space-between);
			list-style: none;
			li{
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					width: 240px;
					text-align: center;
					font-size: 1.5rem;
					font-weight: 400;
				}
				a:hover{
				}
			}
			.page-more{
				color: #014099;
				position: relative;
				padding: 20px 0 10px 0;
			}
			.page-more-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: 5px;
			}
			.page-more-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: -7px;
				top: 50%;
				margin-top: -4px;
				@include trans;
			}
			.page-more:hover{
				.page-more-icon:before{
					right: 15px;
				}
			}
			.active{
				border-bottom: 4px solid #014099;
			}
		}
	}
	@include media(sp){
		#page-nav{
			border-bottom: 1px solid #014099;
			ul{
				@include flex(nowrap,center,space-between);
				list-style: none;
				li{
					width: 100%;
					a,
					a:link,
					a:visited,
					a:active{
						width: auto;
					}
					a:hover{
					}
				}
				.page-more{
					padding: 30px 0 20px 0;
				}
				.page-more-icon{
					width: 10px;
				}
				.page-more-icon:before{
					content: "";
					width: 5px;
					height: 5px;
					display: block;
					right: -5px;
					top: 50%;
					margin-top: -3px;
					@include trans;
				}
				.page-more:hover{
					.page-more-icon:before{
					}
				}
				.active{
				}
			}
		}
	}

	#support-block{
		border-bottom: 1px solid #f0f0f0;
		.support-block-section{
			padding: 0 10px;
			.support-block-section-wrap{
				width: 960px;
				margin: 0 auto;
				@include  flex(wrap,center,space-between);
				padding: 70px 0;
				.support-block-txt{
					width: 480px;
					padding-right: 30px;
					strong{
						font-size: 1.3rem;
						font-weight: 400;
						color: #cd1a59;
					}
					h2{
						font-size: 2rem;
						font-weight: 600;
						color: #18384c;
					}
					p{
						font-size: 1.3rem;
					}
				}
				.support-block-img{
					border-radius: 6px;
					width: 480px;
					height: 280px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				}

				a,
				a:link,
				a:visited,
				a:active{
					display: inline-block;
					// width: 240px;
					text-align: left;
					font-size: 1.5rem;
					font-weight: 400;
					border-bottom: 1px solid #014099;
				}
				a:hover{
				}
				.page-more{
					color: #014099;
					position: relative;
					padding: 20px 50px 10px 10px;
				}
				.page-more-icon{
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: 5px;
				}
				.page-more-icon:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #d2a100;
					border-radius: 5px;
					position: absolute;
					right: -7px;
					top: 50%;
					margin-top: -4px;
					@include trans;
				}
				.page-more:hover{
					.page-more-icon:before{
						right: 15px;
					}
				}
			}
		}
		.support-block-section.one-block{
			.support-block-section-wrap{
				.support-block-txt{
					width: auto;
					strong{
					}
					h2{
					}
					p{
					}
				}
				.support-block-img{
				}
			}
		}
		.support-block-section:nth-child(odd){
			background: #f4f8fb;
		}
	}
	#support-link-block{
		width: 960px;
		margin: 0 auto 50px auto;
		ul{
			@include  flex(nowrap,center,space-between);
			list-style: none;
			margin: 0;
			padding: 30px 0 0 0;
			li{
				.scene-more-icon{
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 15px;
					top: 50%;
					margin-top: -1px;
				}
				.scene-more-icon:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #d2a100;
					border-radius: 5px;
					position: absolute;
					right: -7px;
					top: 50%;
					margin-top: -3px;
					@include trans;
				}
				a,
				a:link,
				a:visited,
				a:active{
					display: inline-block;
					text-align: center;
					width: auto;
					padding: 15px 50px 15px 30px;
					font-size: 1.4rem;
					position: relative;
				}
				a:hover{
					.scene-more-icon:before{
						right: 15px;
					}
				}
			}
			li:nth-child(odd){
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					background: #014099;
					color: #fff;
					margin: 0 30px 0 0;
					border-bottom: 1px solid #014099;
				}
				a:hover{
					color: #014099;
					background: #fff;
				}
			}
			li:nth-child(even){
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					color: #014099;
					background: #fff;
					border-bottom: 1px solid #014099;
				}
				a:hover{
					background: #014099;
					color: #fff;
				}
			}
		}
	}
	@include media(sp){
		#support-block{
			.support-block-section{
				.support-block-section-wrap{
					width: auto;
					flex-direction: column-reverse;
					padding: 50px 0;
					.support-block-txt{
						width: auto;
						strong{
							font-size: 1.3rem;
							font-weight: 400;
							color: #cd1a59;
						}
						h2{
							font-size: 2rem;
							font-weight: 600;
							color: #18384c;
						}
						p{
							font-size: 1.3rem;
						}
					}
					.support-block-img{
						border-radius: 6px;
						width: 100%;
						height: 280px;
						margin-bottom: 20px;
					}

					a,
					a:link,
					a:visited,
					a:active{
						display: inline-block;
						// width: 240px;
						text-align: left;
						font-size: 1.5rem;
						font-weight: 400;
						border-bottom: 1px solid #014099;
					}
					a:hover{
					}
					.page-more{
						margin-bottom: 20px;
					}
					.page-more-icon{
					}
					.page-more-icon:before{
					}
					.page-more:hover{
						.page-more-icon:before{
						}
					}
				}
			}
			.support-block-section.one-block{
				.support-block-section-wrap{
					.support-block-txt{
						width: auto;
						strong{
						}
						h2{
						}
						p{
						}
					}
					.support-block-img{
					}
				}
			}
			.support-block-section:nth-child(odd){
				background: #f4f8fb;
			}
		}
		#support-link-block{
		width: auto;
		ul{
			display: block;
			padding: 0 20px;
			li{
				.scene-more-icon{
				}
				.scene-more-icon:before{
				}
				a,
				a:link,
				a:visited,
				a:active{
					padding: 20px 50px 20px 30px;
				}
				a:hover{
					.scene-more-icon:before{
					}
				}
			}
			li:nth-child(odd){
				a,
				a:link,
				a:visited,
				a:active{
					margin: 20px 0;
					display: block;
				}
				a:hover{
					color: #014099;
					background: #fff;
				}
			}
			li:nth-child(even){
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
					margin: 20px 0;
					display: block;
				}
			}
		}
	}
	}

	#support-base-block{
		margin: 110px auto 100px auto;
		width: 960px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		padding: 50px;
		.support-block-section{
			padding: 0 0 20px 0;
			.support-block-txt{
				width: 480px;
				padding-right: 30px;
				strong{
					font-size: 1.3rem;
					font-weight: 400;
					color: #cd1a59;
				}
				h2{
					font-size: 2rem;
					font-weight: 600;
					color: #18384c;
				}
				p{
					font-size: 1.3rem;
				}
			}
		}
		.support-block-section.one-block{
			.support-block-section-wrap{
				.support-block-txt{
					width: auto;
					strong{
					}
					h2{
					}
					p{
					}
				}
				.support-block-img{
				}
			}
		}
	}
	@include media(sp){
		#support-base-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.support-block-section{
				.support-block-txt{
					width: auto;
					strong{
						font-size: 1.3rem;
						font-weight: 400;
						color: #cd1a59;
					}
					h2{
						font-size: 2rem;
						font-weight: 600;
						color: #18384c;
					}
					p{
						font-size: 1.3rem;
					}
				}
				.support-block-img{
					border-radius: 6px;
					width: 100%;
					height: 280px;
					margin-bottom: 20px;
				}

				a,
				a:link,
				a:visited,
				a:active{
					display: inline-block;
					// width: 240px;
					text-align: left;
					font-size: 1.5rem;
					font-weight: 400;
					border-bottom: 1px solid #014099;
				}
				a:hover{
				}
				.page-more{
					margin-bottom: 20px;
				}
				.page-more-icon{
				}
				.page-more-icon:before{
				}
				.page-more:hover{
					.page-more-icon:before{
					}
				}
			}
			.support-block-section.one-block{
				.support-block-section-wrap{
					.support-block-txt{
						width: auto;
						strong{
						}
						h2{
						}
						p{
						}
					}
					.support-block-img{
					}
				}
			}
		}
	}
}
@include media(sp){

}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

