#breadcrumbs-wrap{
	min-width: 1200px;
	margin: 0 auto;
	font-size: 1.2rem;
	padding: 10px 40px;
	.hierarchy{
		padding: 0 10px;
	}
	a,
	a:link,
	a:visited,
	a:active{
		text-decoration: underline;
	}
	a:hover{
		text-decoration: none;
	}
}
@include media(sp){
	#breadcrumbs-wrap{
		min-width: auto;
		padding: 10px 10px;
	}
}
@include media(sp){

}

@media screen and (min-width: 980px) {

}
@media screen and (max-width: 980px) {

}

