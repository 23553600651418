body.common.page-template-page-company,
body.common.page-template-page-company-under-page-office,
body.common.page-template-page-company-under-page-info,
body.common.page-template-page-company-under-page{

	header.sub-nav{
		background: #196dc3;
	}

	#main-area{
		height: 240px;
		background-image: url(../img/company/company-top-header.jpg);
		background-repeat: no-repeat;
		background-position: center right;
		background-size: cover;
		#main-area-wrap{
			background-image: auto;
		}
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgb(255, 255, 255) 40%,rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
	}
	@include media(sp){
		#main-area{
			height: 70vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				background-image: url(../img/company/company-top-header-sp.jpg);
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px 50px 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
					margin: 0;
				}
			}
		}
		#main-area:after{
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			overflow:hidden;
			width: 100%;
			background-image: linear-gradient(0deg, white 30%, rgba(255, 255, 255, 0) 100%);
		}
	}

	#page-nav{
		border-bottom: 1px solid #014099;
		ul{
			@include flex(wrap,center,space-between);
			list-style: none;
			li{
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					width: 240px;
					text-align: center;
					font-size: 1.5rem;
					font-weight: 400;
				}
				a:hover{
				}
			}
			.page-more{
				color: #014099;
				position: relative;
				padding: 20px 0 10px 0;
			}
			.page-more-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: 5px;
			}
			.page-more-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: -7px;
				top: 50%;
				margin-top: -4px;
				@include trans;
			}
			.page-more:hover{
				.page-more-icon:before{
					right: 15px;
				}
			}
			.active{
				border-bottom: 4px solid #014099;
			}
		}
	}
	@include media(sp){
		#page-nav{
			border-bottom: 1px solid #014099;
			ul{
				@include flex(nowrap,center,space-between);
				list-style: none;
				li{
					width: 100%;
					a,
					a:link,
					a:visited,
					a:active{
						width: auto;
					}
					a:hover{
					}
				}
				.page-more{
					padding: 30px 0 20px 0;
				}
				.page-more-icon{
					width: 10px;
				}
				.page-more-icon:before{
					content: "";
					width: 5px;
					height: 5px;
					display: block;
					right: -5px;
					top: 50%;
					margin-top: -3px;
					@include trans;
				}
				.page-more:hover{
					.page-more-icon:before{
					}
				}
				.active{
				}
			}
		}
	}
	// #company-policy{
	// 	width: 960px;
	// 	margin: 0 auto;
	// 	padding: 80px 0;
	// 	h2{
	// 		text-align: center;
	// 		font-size: 2rem;
	// 		font-weight: 300;
	// 	}
	// 	h3{
	// 		text-align: center;
	// 		padding-top: 40px;
	// 	}
	// }
	// @include media(sp){
	// 	#company-policy{
	// 		width: auto;
	// 		padding: 40px 10px;
	// 		h2{
	// 		}
	// 		h3{
	// 			padding-top: 10px;
	// 			svg{
	// 				width: 100%;
	// 			}
	// 		}
	// 	}
	// }
	#ceo-block{
		background: #e8f0f9;
		padding: 10px;
		margin: 100px 0 0 0;
		.ceo-inner{
			width: 1200px;
			margin: 0 auto;
			position: relative;
			padding: 0 40px;
			@include flex(wrap,center,space-between);
			-ms-flex-align: center;
			align-items: center;
			img{
				width: 50%;
			}
			h2{
				width: 50%;
				font-size: 3rem;
				font-weight: 600;
				padding: 10px 10px 10px 50px;
				line-height: 1.8;
			}
		}
	}
	@include media(sp){
		#ceo-block{
			.ceo-inner{
				width: auto;
				display: block;
				padding: 0;
				img{
					width: 100%;
				}
				h2{
					width: auto;
					padding: 10px 0;
					text-align: center;
				}
			}
		}
	}
	#ceo-greeting{
		width: 960px;
		margin: 0 auto;
		padding: 70px 10px;
		#ceo-main-greeting{
			margin-bottom: 30px;
			p{
				margin: 0;
				line-height: 2;
			}
		}
		.ceo-main-greeting-coution{
			display: block;
			font-size: 1.3rem;
			margin-top: 30px;
		}
		.ceo-greeting-date{
			text-align: right;
		}
		.ceo-middle-policy{
			text-align: center;
			display: block;
			padding: 30px 10px;
			font-size: 2rem;
			font-weight: 500;
			img{
				width: 700px;
			}
		}
		.ceo-sign{
			text-align: right;
			@include flex(wrap,right,space-between);
			-ms-flex-align: center;
			align-items: center;
			h2{
				font-weight: normal;
				font-size: 1.4rem;
				padding-right: 30px;
			}
			svg{

			}
			img{
				width: 160px;
				height: auto;
			}
		}
	}
	@include media(sp){
		#ceo-greeting{
			width: auto;
			padding: 40px 20px;
			#ceo-main-greeting{
				p{
				}
			}
			.ceo-main-greeting-coution{
				font-size: 1.2rem;
			}
			.ceo-greeting-date{
			}
			.ceo-middle-policy{
				padding: 30px 0;
				img{
					width: 100%;
				}
			}
			.ceo-greeting-date{
			}
			.ceo-sign{
				h2{
				}
				svg{

				}
			}
		}
	}
	#interview{
		width: 960px;
		margin: 0 auto;
		padding: 70px 10px;
		h2{
			margin: 0 0 45px 0;
			position: relative;
			z-index: 5;
			text-align: center;
			font-weight: 400;
			color: #18384c;
			text-transform: uppercase;
			span{
				position: absolute;
				top: -40px;
				left: 50%;
				z-index: 10;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
				color: #edf4f8;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 4rem;
			}
		}
		.interview-txt{
			p{
				line-height: 1.7;
			}
			a,
			a:link,
			a:visited,
			a:active{
				color: #014099;
				text-decoration: underline;
			}
			a:hover{
			}
		}
		#interviewmoview{
			width: 640px;
			margin: 0 auto;
			padding: 20px 10px;
			aspect-ratio: 4 / 3;
			video{
				width: 100%;
				height: 100%;
				aspect-ratio: 4 / 3;
			}
		}
	}
	@include media(sp){
		#interview{
			width: auto;
			h2{
				span{
				}
			}
			.interview-txt{
				p{
				}
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
				}
			}
			#interviewmoview{
				width: 100%;
				height: 100%;
				aspect-ratio: 4 / 3;
				video{
					width: 100%;
					height: 100%;
					aspect-ratio: 4 / 3;
				}
			}
		}
	}
	#youtube{
		width: 960px;
		margin: 0 auto;
		padding: 70px 10px 0px 10px;
		h2{
			margin: 0 0 45px 0;
			position: relative;
			z-index: 5;
			text-align: center;
			font-weight: 400;
			color: #18384c;
			text-transform: uppercase;
			span{
				position: absolute;
				top: -40px;
				left: 50%;
				z-index: 10;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
				color: #edf4f8;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 4rem;
			}
		}
		.youtube-txt{
			p{
				line-height: 1.7;
			}
			a,
			a:link,
			a:visited,
			a:active{
				color: #014099;
				text-decoration: underline;
			}
			a:hover{
			}
		}
		#youtubemoview{
			width: 640px;
			margin: 0 auto;
			padding: 20px 10px;
			iframe {
				width: 100%;
				height: auto;
				aspect-ratio: 16/9;
			}
		}
	}
	@include media(sp){
		#youtube{
			width: auto;
			h2{
				span{
				}
			}
			.youtube-txt{
				p{
				}
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
				}
			}
			#youtubemoview{
				width: 100%;
				height: 100%;
				aspect-ratio: 4 / 3;
				video{
					width: 100%;
					height: 100%;
					aspect-ratio: 4 / 3;
				}
			}
		}
	}
	#page-footer-nav{
		background: #eeeeee;
		width: 1200px;
		margin: 0 auto 80px auto;
		padding: 20px 10px;
		nav{
			ul{
				@include flex(wrap,center,space-between);
				list-style: none;
				li{
					padding: 0 5px;
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						width: 260px;
						text-align: center;
						font-size: 1.5rem;
						font-weight: 400;
					}
					a:hover{
					}
				}
				.page-more{
					color: #014099;
					position: relative;
					padding: 30px 0 30px 0;
					background: #fff;
					border-bottom: 1px solid #014099;
				}
				.page-more-icon{
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 25px;
					top: 50%;
					margin-top: -2px;
				}
				.page-more-icon:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #d2a100;
					border-radius: 5px;
					position: absolute;
					right: -7px;
					top: 50%;
					margin-top: -4px;
					@include trans;
				}
				.page-more:hover{
					.page-more-icon:before{
						right: 15px;
					}
				}
				.active{
				}
			}
		}
	}
	@include media(sp){
		#page-footer-nav{
			width: auto;
			nav{
				ul{
					display: block;
					li{
						padding: 5px 5px;
						a,
						a:link,
						a:visited,
						a:active{
							width: 100%;
						}
						a:hover{
						}
					}
					.page-more{
					}
					.page-more-icon{
					}
					.page-more-icon:before{
					}
					.page-more:hover{
						.page-more-icon:before{
						}
					}
					.active{
					}
				}
			}
		}
	}
	#company-overview{
		width: 960px;
		margin: 0 auto;
		padding: 80px 10px;
		h2{
			font-size: 2.4rem;
			font-weight: 300;
			padding: 15px 0;
			margin: 0;
		}
	}
	.overview-table:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
	}
	@include media(sp){
		#company-overview{
			width: auto;
			h2{
			}
		}
		.overview-table:before{
		}
	}
	.overview-table{
		table{
			tr{
				th,td{
					padding: 30px 0 30px 10px;
					text-align: left;
				}
				th{
					font-size: 1.5rem;
					font-weight: 400;
					color: #014099;
					width: 200px;
					border-bottom: 1px solid #014099;
					border-top: 1px solid #014099;
				}
				td{
					font-weight: 300;
					width: 760px;
					border-bottom: 1px solid #dcdcdc;
					border-top: 1px solid #dcdcdc;
				}
				ul{
					padding: 10px 0 10px 1.7rem;
					list-style: square;
					li::marker {
						color: #014099;
					}
				}
			}
		}
		h3{
			font-size: 2.4rem;
			color: #19a8c2;
			margin: 50px 0 25px 0;
		}
		.company-history{
			padding-left: 30px;
		}
	}
	@include media(sp){
		.overview-table{
			table{
				tr{
					th,td{
					}
					th{
					}
					td{
					}
					ul{
						li::marker {
						}
					}
				}
			}
			h3{
			}
			.company-history{
				padding-left: 0;
			}
		}
	}
	.company-map{
		width: 100%;
		position: relative;
		line-height: 0;
		iframe{
			border: none;
			height: 400px;
			width: 100%;
		}
		.map-hover{
			content: " ";
			display: block;
			width: 100%;
			height: 100%;
			background: #014099;
			opacity: 0.25;
			position: absolute;
			top: 0;
			left:0;
		}
	}
	@include media(sp){
	}
	.list-nav{
		list-style: none;
		@include flex(wrap,left,space-between);
		margin: 40px 0;
		li{
			padding-right: 40px;
			a,
			a:link,
			a:visited,
			a:active{
				color: #19a8c2;
				font-weight: 500;
				.fas{
					margin-right: 10px;
				}
			}
			a:hover{
			}

		}
	}
	@include media(sp){
	}



	.office-map{
		// min-height: 460px;
		background: url(../img/company/company-office-map.jpg) no-repeat center #dee6ec;
		margin-bottom: 50px;
		.office-map-list-block{
			background: rgba(255, 255, 255, 0.6);
			float: right;
			height: 100%;
			width: 420px;
			// @include flex(wrap,right,space-between);
			.office-map-list{
				padding: 30px 10px 30px 30px;
				@include flex(wrap,left,space-between);
				-ms-flex-align: center;
				align-items: center;
				border-bottom: 1px solid #d2d9de;
				h3{
					color: #18384c;
					font-size: 1.6rem;
					font-weight: 400;
					margin: 0;
					width: 50%;
					span{
						font-size: 1.2rem;
					}
					.fas{
						padding-right: 20px;
					}
				}
				ul{
					list-style: none;
					padding: 0;
					margin: 0;
					width: 50%;
					@include flex(wrap,left,space-between);
					li{
						font-size: 1.2rem;
						width: 50%;
					}
				}
			}
		}
	}
	@include media(sp){
		.office-map{
			background-position: top center;
			background-size: contain;
    		.office-map-list-block{
    			padding-top: 200px;
				float: none;
				width: auto;
				.office-map-list{
					padding: 20px 10px 20px 10px;
					display: block;
					h3{
						width: auto;
						span{
						}
						.fas{
							padding-right: 10px;
						}
					}
					ul{
						width: auto;
						padding-top: 20px;
						li{
							font-size: 1.2rem;
							width: 33.33333%;
						}
					}
				}
			}
		}
	}
	.office-table{
		table{
			margin-left: 1em;
			thead{
				tr{
					th,td{
						padding: 15px 0 15px 10px;
						text-align: left;
						font-weight: 600;
						font-size: 1.3rem;
						color: #18384c;
					}
					th{
						width: 200px;
						border-bottom: 1px solid #014099;
					}
					td{
						width: 530px;
						border-bottom: 1px solid #dcdcdc;
					}
					.address{

					}
					.phone{
						width: 200px;
						border-bottom: 1px solid #18384c;
					}
				}
			}
			tbody{
				tr{
					th,td{
						padding: 30px 0 30px 10px;
						text-align: left;
					}
					th{
						font-size: 1.5rem;
						font-weight: 400;
						color: #014099;
						width: 200px;
						border-bottom: 1px solid #014099;
						border-top: 1px solid #014099;
					}
					td{
						font-weight: 300;
						width: 530px;
						border-bottom: 1px solid #dcdcdc;
						border-top: 1px solid #dcdcdc;
					}
					.address{
						font-size: 1.5rem;
						a,
						a:link,
						a:visited,
						a:active{
							color: #d2a100;
							text-decoration: underline;
							.fas{
								color: #18384c;
								padding-left: 10px;
							}
						}
						a:hover{
							text-decoration: none;
							.fas{
								color: #d2a100;
							}
						}
					}
					.phone{
						width: 200px;
						border-bottom: 1px solid #18384c;
						border-top: 1px solid #18384c;
					}
				}
			}
		}
		.office-ttl{
			font-size: 1.6rem;
			color: #014099;
			margin: 40px 0 10px 0;
		}
		.office-ttl:before{
			content: " ";
			display: inline-block;
			width: 13px;
			height: 13px;
			border: 3px solid #014099;
			border-radius: 10px;
			vertical-align: middle;
			margin-right: 5px;
		}
		.company-history{
			padding-left: 30px;
		}
	}
	@include media(sp){
		.office-table{
			table{
				margin-left: 0;
				display: block;
				thead{
					display: none;
					tr{
						th,td{
						}
						th{
						}
						td{
						}
						.address{

						}
						.phone{
						}
					}
				}
				tbody{
					display: block;
					tr{
						display: block;
						padding-bottom: 20px;
						th,td{
							display: block;
							width: 100%;
							padding: 20px 10px 0px 10px;
						}
						th{
							border-bottom: none;
						}
						th:before{
							content: "支店・営業所名";
							display: block;

							font-weight: 600;
							font-size: 1.3rem;
							color: #18384c;
						}
						td{
						}
						.address{
							width: 100%;
							border-top: none;
							border-bottom: none;
							a,
							a:link,
							a:visited,
							a:active{
								.fas{
								}
							}
							a:hover{
								.fas{
								}
							}
						}
						.address:before{
							content: "住所";
							display: block;

							font-weight: 600;
							font-size: 1.3rem;
							color: #18384c;
						}
						.phone{
							width: 100%;
							border-top: none;
							border-bottom: none;
						}
						.phone:before{
							content: "TEL/FAX";
							display: block;

							font-weight: 600;
							font-size: 1.3rem;
							color: #18384c;
						}
					}
				}
			}
			h3{
			}
			h3:before{
			}
			.company-history{
			}
		}
	}

	.office-table:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
	}
}



body.common.page-template-page-company-under-page{
	.corporation-block{
		// width: 860px;
		margin: 110px auto 100px auto;
		// -moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		// -webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		// -ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		// box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);


		width: 960px;
		// margin: 30px auto 70px auto;
		// padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		.corporation-block-inner{
			padding: 0 50px 50px 50px;
		}
		.corporation-block-inner:first-child{
			padding-top: 50px;
		}
		.corporation-block-detail{
			p{
				line-height: 1.8;
			}
		}
		h2{
			margin: 0;
			font-size: 2.2rem;
			font-weight: 600;
			padding: 40px 0 20px 0;
		}
		h2:first-child{
			padding-top: 0;
		}
		h3{
			margin: 0;
			font-size: 2rem;
			font-weight: 600;
			padding: 0 0 18px 0;
			img{
				width: 2.5rem;
				margin-right: 10px;
			}
		}
		h4{
			margin-top: 0;
			font-size: 1.6rem;
		}
		p{
			margin: 0;
			font-size: 1.3rem;
			font-weight: 300;
		}
		img.icon10{
			display: inline;
			width: 70px;
			margin: 0 10px 0 0;
			vertical-align: middle;
		}
		table{
			border-collapse: separate;
			border-spacing: 5px;
			tbody{

			}
			tr{
				th,td{
					font-size: 1.3rem;
					font-weight: normal;
				}
				th{
					width: 20%;
					background: #d4f0f8;
					padding: 20px 5px;
				}
				td{
					width: 80%;
					border: 1px solid #f0f4f9;
					padding: 20px 30px 20px 30px;
				}
			}
			tr:nth-child(even){
				td{
					border: none;
					background: #f0f4f9;
				}
			}
		}
		.product-disc-block-detail{
			padding: 30px 0 20px 0;
			h3{
				margin: 50px 0 0 0;
				padding: 0;
			}
			video{
				width: 520px;
				height: auto;
				display: block;
				margin: 40px auto 0 auto;
			}
			img{
				width: auto;
				height: auto;
				display: block;
				// margin: 20px auto 0 auto;
				margin: 0 auto 0 auto;
			}
			a,
			a:link,
			a:visited,
			a:active{
			}
			a:hover{
				@include hover;
			}
		}
	}
	@include media(sp){
		.corporation-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.corporation-block-inner{
				padding: 0;
			}
			.corporation-block-inner:first-child{
				padding: 0;
			}
			h2{
				font-size: 2rem;
				padding: 30px 0 10px 0;
			}
			h2:first-child{
				padding-top: 0;
			}
			h3{
				font-size: 1.8rem;
				img{
				}
			}
			table{
				border-collapse: initial;
				border-spacing: 0;
				display: block;
				tbody{
					display: block;
				}
				tr{
					display: block;
					th,td{
						display: block;
						font-size: 1.3rem;
						font-weight: normal;
						text-align: left;
						margin: 6px 0;
					}
					th{
						width: 100%;
						background: #d4f0f8;
						padding: 14px 20px 14px 20px;
						margin: 20px 0 6px 0;
					}
					td{
						width: 100%;
						border: 1px solid #f0f4f9;
						padding: 14px 20px 14px 20px;
					}
				}
				tr:nth-child(even){
					td{
						border: 1px solid #f0f4f9;
						background: #fff;
					}
				}
			}
			.product-disc-block-detail{

				video,
				img{
					margin: 20px auto 0 auto;
					width: 100%;
				}
			}
		}
	}

	.seminer-block,
	.gallery-block{
		width: 960px;
		margin: 110px auto 100px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		.seminer-block-txt{
			p{
				line-height: 1.8;
			}
		}
		.seminer-block-2cul{
			@include flex(wrap,left,space-between);
			.seminer-block-2cul-block{
				width: 50%;
				padding: 2px;
			}
		}
		.seminer-block-3cul{
			@include flex(wrap,left,space-between);
			img{
				width: 33.3333333333%;
				padding: 2px;
			}
		}
		.seminer-block-4cul{
			@include flex(wrap,left,space-between);
			img{
				width: 25%;
				padding: 2px;
			}
		}
		h2{
			margin: 0;
			font-size: 2.2rem;
			font-weight: 600;
			padding: 40px 0 20px 0;
		}
		h2:first-child{
			padding-top: 0;
		}
		.gallery-block-wrap{
			@include flex(wrap,left,space-between);
			a,
			a:link,
			a:visited,
			a:active{
				display: block;
				width: 280px;
				height: 200px;
				margin: 4px;
				background-position: center;
				background-size: cover;
			}
			a:hover{
				.fas{
				}
			}
		}
	}
	@include media(sp){
		.seminer-block,
		.gallery-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.seminer-block-txt{
				p{
					line-height: 1.8;
				}
			}
			.seminer-block-2cul{
				display: block;
				.seminer-block-2cul-block{
					width: 100%;
					margin-bottom: 10px;
				}
			}
			.seminer-block-3cul{
				img{
					width: 50%;
				}
			}
			.seminer-block-4cul{
				img{
					width: 50%;
				}
			}
			h2{
				margin: 0;
				font-size: 2.2rem;
				font-weight: 600;
				padding: 40px 0 20px 0;
			}
			h2:first-child{
				padding-top: 0;
			}
			.gallery-block-wrap{
				@include flex(wrap,center,space-between);
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					width: 49%;
					height: 100px;
					margin: 0.5%;
					background-position: center;
					background-size: cover;
				}
				a:hover{
					.fas{
					}
				}
			}
			
		}
	}
}




@include media(sp){

}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

