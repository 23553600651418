body.common.page-template-page-recruit,
body.common.page-template-page-recruit-page,
body.common.page-template-page-recruit-voice{
	header.sub-nav{
		background: #f7a017;
	}
	#main-area{
		height: 500px;
		background: url(../img/ir-top-header.jpg) no-repeat right;
		background-size: cover;
		margin-bottom: 50px;
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 50%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.7) 100%);
	}
	@include media(sp){
		#main-area{
			height: 50vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
				background-image: url(../img/ir-header-sp.jpg);
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			width: 100%;
			background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
		}
	}

	#lead-area{
		background: url(../img/recruit/recruit-bg.jpg) repeat center;
		#lead-area-wrap{
			width: 960px;
			margin: 30px auto 70px auto;
			padding: 65px 45px 50px 45px;
			@include flex(inherit,left,space-between);
			.lead-area-copy{
				width: 60%;
				h2{
					font-size: 2rem;
				}
				p{
					line-height: 2;
				}
			}
			.lead-area-leaf{
				width: 40%;
				img{

				}
			}
		}
	}
	@include media(sp){
		#lead-area{
			#lead-area-wrap{
				width: auto;
				padding: 30px 20px 20px 20px;
				.lead-area-copy{
					width: auto;
					h2{
						font-size: 2rem;
					}
					p{
						line-height: 2;
					}
				}
				.lead-area-leaf{
					display: none;
					img{

					}
				}
			}
		}
	}
	#voice-area{
		.voice-area-inner{
			width: 960px;
			margin: 30px auto 0 auto;
			padding: 30px 45px 20px 45px;
			@include flex(wrap,left,space-between);
			.voice-area-copy{
				width: 60%;
				h2{
					font-size: 2rem;
				}
				p{
					line-height: 2;
				}
			}
			.voice-area-leaf{
				width: 40%;
				img{

				}
			}
		}
		.voice-area-wrap:nth-child(even){
			.voice-area-inner{
				flex-direction: row-reverse;
				.voice-area-copy{
					padding-left: 30px;
				}
			}
		}
		.voice-btn-block{
			@include flex(wrap,center,space-between);
			width: 960px;
			margin: 0 auto 30px auto;
			background: #eeeeee;
			padding: 20px 0 0 0;
			.voice-btn{
				.scene-more-icon{
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 15px;
					top: 50%;
					margin-top: -1px;
				}
				.scene-more-icon:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #d2a100;
					border-radius: 5px;
					position: absolute;
					right: -7px;
					top: 50%;
					margin-top: -3px;
					@include trans;
				}
				a,
				a:link,
				a:visited,
				a:active{
					text-align: center;
					width: 200px;
					padding: 12px 10px;
					font-size: 1.4rem;
					position: relative;
					display: block;
					background: #014099;
					color: #fff;
					margin: 0 10px 20px 10px;
					border: 1px solid #014099;
				}
				a:hover{
					color: #014099;
					background: #fff;
					.scene-more-icon:before{
						right: 15px;
					}
				}
			}
		}
		.recruit-btn{
			width: 960px;
			@include flex(wrap,center,space-between);
			margin: 0 auto 30px auto;
			.scene-more-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 15px;
				top: 50%;
				margin-top: -1px;
			}
			.scene-more-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: -7px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
			a,
			a:link,
			a:visited,
			a:active{
				text-align: center;
				width: 440px;
				padding: 20px 10px;
				font-size: 1.4rem;
				position: relative;
				display: block;
				background: #014099;
				color: #fff;
				margin: 0 auto 30px auto;
				border-bottom: 1px solid #014099;
			}
			a:hover{
				color: #014099;
				background: #fff;
				.scene-more-icon:before{
					right: 15px;
				}
			}
		}
		@include media(sp){
			.recruit-btn{
				display: block;
				width: auto;
				.scene-more-icon{
				}
				.scene-more-icon:before{
				}
				a,
				a:link,
				a:visited,
				a:active{
					width: 90%;
				}
				a:hover{
					.scene-more-icon:before{
					}
				}
			}
		}
	}
	@include media(sp){
		#voice-area{
			.voice-area-wrap{
				.voice-area-inner{
					flex-direction: column-reverse;
				}
			}
			.voice-area-inner{
				width: auto;
				padding: 20px;
				.voice-area-copy{
					width: auto;
					h2{
						font-size: 2rem;
					}
					p{
						line-height: 2;
					}
				}
				.voice-area-leaf{
					width: auto;
					img{

					}
				}
			}
			.voice-area-wrap:nth-child(even){
				.voice-area-inner{
					.voice-area-copy{
						padding-left: 0;
					}
				}
			}
			.voice-btn-block{
				display: block;
				width: auto;
				.voice-btn{
					.scene-more-icon{
					}
					.scene-more-icon:before{
					}
					a,
					a:link,
					a:visited,
					a:active{
						width: 90%;
						margin: 0 auto 20px auto;
					}
					a:hover{
						.scene-more-icon:before{
						}
					}
				}
			}
		}
	}


	.recruit-data{
		width: 960px;
		margin: 0 auto;
		padding: 40px 10px;
		.recruit-data-block{
			// display: flex;
			// -ms-flex-align: stretch;
			// align-items: stretch;
		}
		.recruit-data-name{
			@include  flex(wrap,center,center);
			-ms-flex-align: center;
			align-items: center;
		}
		h2{
			font-size: 2rem;
			font-weight: 600;
			color: #18384c;
			margin: 0;
			span{
				display: block;
				font-size: 2rem;
				font-weight: 600;
				color: #014099;
			}
		}
		.recruit-data-list{
			// width: 760px;
			padding: 30px 20px 10px 20px;
			dl{
				margin: 0;
				@include  flex(wrap,center,center);
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-align: stretch;
				align-items: stretch;
				dt{
					font-size: 1.4rem;
					font-weight: 400;
					color: #014099;
					width: 15%;
					padding: 5px 0;
					border-top: 1px solid #014099;
					padding: 30px 20px;
					// width: 200px;
					word-break: break-all;
					display: flex;
					-ms-flex-align: center;
					align-items: center;
				}
				dd{
					margin: 0;
					font-size: 1.4rem;
					font-weight: 400;
					width: 85%;
					border-top: 1px solid #dcdcdc;
					padding: 30px 20px;
					// width: 700px;
					word-break: break-all;
					display: flex;
					-ms-flex-align: center;
					align-items: center;
				}
				ul{
					padding-left: 1.5rem;
					li{
						font-size: 1.4rem;
						font-weight: 400;
						line-height: 1.8;
					}
				}
			}
			dl:last-child{
				dt{
					border-bottom: 1px solid #014099;
				}
				dd{
					border-bottom: 1px solid #dcdcdc;
				}
			}
		}
		.recruit-data-block-option{
			padding: 0 20px 30px 20px;
			p{
				font-size: 1.2rem;
				line-height: 1.9;
			}
		}
		.recruit-btn{
			@include flex(wrap,center,space-between);
			margin: 0 auto 30px auto;
			.scene-more-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 15px;
				top: 50%;
				margin-top: -1px;
			}
			.scene-more-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: -7px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
			a,
			a:link,
			a:visited,
			a:active{
				text-align: center;
				width: 440px;
				padding: 20px 10px;
				font-size: 1.4rem;
				position: relative;
				display: block;
				background: #014099;
				color: #fff;
				margin: 0 auto 30px auto;
				border-bottom: 1px solid #014099;
			}
			a:hover{
				color: #014099;
				background: #fff;
				.scene-more-icon:before{
					right: 15px;
				}
			}
		}
		@include media(sp){
			.recruit-btn{
				display: block;
				.scene-more-icon{
				}
				.scene-more-icon:before{
				}
				a,
				a:link,
				a:visited,
				a:active{
					width: 100%;
				}
				a:hover{
					.scene-more-icon:before{
					}
				}
			}
		}
	}
	.recruit-data.recruit-data-point{
		.recruit-data-block{
		}
		.recruit-data-name{
			@include  flex(wrap,center,center);
			-ms-flex-align: center;
			align-items: center;
		}
		h2{
			font-size: 2.4rem;
			font-weight: 300;
			color: #18384c;
			margin: 0;
		}
		h3{
			font-size: 1.6rem;
			font-weight: 500;
			color: #f7a017;
			margin:40px 0 0 0;
			padding: 0 0 0 20px;
			position: relative;
		}
		h3:before{
			content: "";
			width: 14px;
			height: 14px;
			display: block;
			background: #fff;
			border: 3px solid #f7a017;
			border-radius: 8px;
			position: absolute;
			left: 0px;
			top: 50%;
			margin-top: -5px;
		}
		.recruit-data-list{
			dl{
				dt{
					border-top: 1px solid #dcdcdc;
				}
				dd{
					border-top: 1px solid #dcdcdc;
				}
			}
			dl:last-child{
				dt{
					border-bottom: 1px solid #dcdcdc;
				}
				dd{
					border-bottom: 1px solid #dcdcdc;
				}
			}
		}
		.recruit-data-btn{
			padding: 10px 10px 10px 10px;
			@include  flex(wrap,center,center);
		}
		.recruit-btn{
			padding: 5px;
			a,
			a:link,
			a:visited,
			a:active{
				width: 220px;
				padding: 18px 10px;
				margin: 0;
			}
			a:hover{
				color: #014099;
				background: #fff;
				.scene-more-icon:before{
					right: 15px;
				}
			}
		}
		@include media(sp){
			.recruit-data-btn{
				padding: 10px 0 10px 0;
			}
			.recruit-btn{
				padding: 5px;
				width: 50%;
				a,
				a:link,
				a:visited,
				a:active{
					padding: 18px 10px;
					margin: 0;
					width: auto;
				}
				a:hover{
					color: #014099;
					background: #fff;
					.scene-more-icon:before{
						right: 15px;
					}
				}
			}
		}
	}
	.recruit-data:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
		margin-bottom: 15px;
	}
	@include media(sp){
		.recruit-data{
			width: auto;
			.recruit-data-block{
				display: block;
			}
			.recruit-data-name{
				display: block;
				border-bottom: none;
				border-top: 1px solid #014099;
				padding: 20px 10px;
			}
			h2{
				font-size: 1.6rem;
				span{
					font-size: 2rem;
				}
			}
			.recruit-data-list{
				width: auto;
				padding: 10px 0;
				dl{
					display: block;
					dt{
						font-weight: 600;
						width: auto;
						padding: 20px 10px;
					}
					dd{
						margin: 0;
						width: auto;
						padding: 20px 10px;
					}
				}
			}
		}
		.recruit-data:before{
			content: " ";
			display: block;
			width: 5px;
			height: 5px;
			background: #014099;
			margin-bottom: 15px;
		}
		.recruit-data.recruit-data-point{
			.recruit-data-block{
			}
			.recruit-data-name{
			}
			h2{
				span{
				}
			}
			h3{
				font-size: 1.5rem;
				margin: 20px 0 10px 0;
			}
			h3:before{
			}
			.recruit-data-list{
				width: auto;
				padding: 10px 0;
				dl{
					display: block;
					dt{
						font-weight: 600;
						width: auto;
						padding: 20px 10px;
					}
					dd{
						margin: 0;
						width: auto;
						padding: 20px 10px;
					}
				}
				dl:last-child{
					dt{
						border-bottom: none;
					}
					dd{
						border-bottom: 1px solid #dcdcdc;
					}
				}
			}
		}
	}
	.recruit-flow{
		width: 960px;
		margin: 0 auto;
		padding: 40px 10px;
		h2{
			font-size: 2.4rem;
			font-weight: 300;
			color: #18384c;
			margin: 0;
		}
		.recruit-flow-block{
			margin-top: 30px;
			@include  flex(wrap,space-between,center);
			-ms-flex-align: stretch;
			align-items: stretch;
			position: relative;
			.recruit-flow-block-list{
				padding: 20px 20px;
				width: 130px;
				height: 130px;
				text-align: center;
				border: 1px solid #dcdcdc;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				position: relative;
				h4{
					font-size: 1.6rem;
					font-weight: 500;
					margin: 0;
					display: block;
					width: 100%;
				}
				span{
					font-size: 1.4rem;
					font-weight: 400;
					display: block;
					width: 100%;
				}
			}
			.recruit-flow-block-list:after{
			  content: "";
			  position: absolute;
			  right: -40px;
			  top: 50%;
			  width: 0px;
			  height: 0px;
			  margin: -20px auto auto auto;
			  border-style: solid;
			  border-color: #dcdcdc transparent transparent transparent;
			  border-width: 20px 20px 20px 20px;
			  -ms-transform:rotate(-90deg);
			  -moz-transform:rotate(-90deg);
			  -webkit-transform:rotate(-90deg);
			  transform:rotate(-90deg);
			}
			.recruit-flow-block-list:last-child{
				background: #014099;
				color: #fff;
			}
			.recruit-flow-block-list:last-child:after{
				display: none;
			}
		}
	}
	.recruit-flow:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
		margin-bottom: 15px;
	}
	@include media(sp){
		.recruit-flow{
			width: auto;
			padding: 40px 20px;
			h2{
				font-size: 2.4rem;
				font-weight: 300;
				color: #18384c;
				margin: 0;
			}
			.recruit-flow-block{
				.recruit-flow-block-list{
					padding: 20px 20px;
					width: 50%;
					height: 120px;
					margin: 10px 0;
					h4{
						font-size: 1.6rem;
					}
					span{
						font-size: 1.4rem;
						font-weight: 400;
					}
				}
				.recruit-flow-block-list:after{
				}
				.recruit-flow-block-list:last-child{
					width: 100%;
				}
			}
		}
		.recruit-flow:before{
		}
	}

	#recruit-footer-link{
		margin-bottom: 80px;
		.recruit-footer-link-block{
			max-width: 1200px;
			margin: 0 auto;
			@include  flex(wrap,center,center);
			a.recruit-footer-links,
			a.recruit-footer-links:link,
			a.recruit-footer-links:visited,
			a.recruit-footer-links:active{
				position: relative;
				display: block;
				width: 400px;
				-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				background: #fff;
				h3{
					font-size: 2rem;
					font-weight: 600;
					background: #014099;
					color: #fff;
					width: 320px;
					margin: -30px 0 0 0;
					padding: 16px 0 16px 30px;
				}
			}
			.recruit-footer-links-img{
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				width: 400px;
				height: 240px;
			}
			.recruit-footer-link-txt{
				padding: 24px 30px 90px 30px;
				font-size: 1.3rem;
			}
			.more-link{
				position: absolute;
				right: 30px;
				bottom: 30px;
				display: block;
				margin: 0;
				padding: 0 50px 10px 4px;
				border-bottom: 1px solid #014099;
			}
			.scene-more-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				margin-top: -1px;
				position: absolute;
				right: 10px;
				bottom: 18px;
			}
			.scene-more-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: -7px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
			a.recruit-footer-links:hover{
				.scene-more-icon:before{
					right: 15px;
				}
			}
		}
	}
	@include media(sp){
		#recruit-footer-link{
			.recruit-footer-link-block{
				a.recruit-footer-links,
				a.recruit-footer-links:link,
				a.recruit-footer-links:visited,
				a.recruit-footer-links:active{
					width: 100%;
					h3{
					}
				}
				.recruit-footer-links-img{
					width: 100%;
				}
				.recruit-footer-link-txt{
				}
				.more-link{
				}
				.scene-more-icon{
				}
				.scene-more-icon:before{
				}
				a.recruit-footer-links:hover{
					.scene-more-icon:before{
					}
				}
			}
		}
	}
	.recruit-contact{
		.recruit-contact-block{
			width: 860px;
			margin: 0 auto 80px auto;
			-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			padding: 50px 30px;
			h3{
				margin: 0 0 20px 0;
				font-size: 2rem;
				font-weight: 500;
				text-align: center;
			}
			.recruit-contact-block-txt{
				font-size: 1.3rem;
				text-align: center;
			}
			.recruit-contact-block-member{
				font-size: 1.3rem;
				text-align: center;
				display: block;
				padding: 20px 0;
			}
			.recruit-contact-tel{
				display: block;
				text-transform: underline;
				font-size: 1.8rem;
				text-align: center;
				font-weight: 500;
			}
			.recruit-contact-time{
				font-size: 1.3rem;
				text-align: center;
			}
			.recruit-btn{
				.scene-more-icon{
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 15px;
					top: 50%;
					margin-top: -1px;
				}
				.scene-more-icon:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #d2a100;
					border-radius: 5px;
					position: absolute;
					right: -7px;
					top: 50%;
					margin-top: -3px;
					@include trans;
				}
				a,
				a:link,
				a:visited,
				a:active{
					text-align: center;
					width: 440px;
					padding: 20px 10px;
					font-size: 1.4rem;
					position: relative;
					display: block;
					background: #014099;
					color: #fff;
					margin: 0 auto 30px auto;
					border-bottom: 1px solid #014099;
				}
				a:hover{
					color: #014099;
					background: #fff;
					.scene-more-icon:before{
						right: 15px;
					}
				}
			}
			@include media(sp){
				.recruit-btn{
					.scene-more-icon{
					}
					.scene-more-icon:before{
					}
					a,
					a:link,
					a:visited,
					a:active{
						width: 100%;
					}
					a:hover{
						.scene-more-icon:before{
						}
					}
				}
			}
		}
	}
	@include media(sp){
		.recruit-contact{
			.recruit-contact-block{
				width: auto;
				margin: 0 auto 80px auto;
				padding: 40px 20px;
				h3{
					margin: 0 0 20px 0;
					font-size: 2rem;
					font-weight: 500;
					text-align: center;
				}
				.recruit-contact-block-txt{
					font-size: 1.3rem;
					text-align: center;
				}
				.recruit-contact-block-member{
					font-size: 1.3rem;
					text-align: center;
					display: block;
					padding: 20px 0;
				}
				.recruit-contact-tel{
					display: block;
					text-transform: underline;
					font-size: 1.8rem;
					text-align: center;
					font-weight: 500;
				}
				.recruit-contact-time{
					font-size: 1.3rem;
					text-align: center;
				}
			}
		}
	}
}

body.common.page-template-page-recruit-page,
body.common.page-template-page-recruit-voice{
	#main-area{
		height: 240px;
		background: url(../img/ir-top-header.jpg) no-repeat right;
		background-size: contain;
		margin-bottom: 50px;
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 50%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.7) 100%);
	}
	@include media(sp){
		#main-area{
			height: 50vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
				background-image: url(../img/ir-header-sp.jpg);
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			width: 100%;
			background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
		}
	}
	.recruit-point-anker{
		@include flex(wrap,center,space-between);
		width: 960px;
		margin: 0 auto 30px auto;
		background: #eeeeee;
		padding: 20px 0 0 0;
		
		.scene-more-icon{
			display: block;
			height: 1px;
			width: 20px;
			background: #d2a100;
			position: absolute;
			right: 15px;
			top: 50%;
			margin-top: -1px;
		}
		.scene-more-icon:before{
			content: "";
			width: 7px;
			height: 7px;
			display: block;
			background: #d2a100;
			border-radius: 5px;
			position: absolute;
			right: -7px;
			top: 50%;
			margin-top: -3px;
			@include trans;
		}
		a,
		a:link,
		a:visited,
		a:active{
			text-align: center;
			width: 200px;
			padding: 12px 10px;
			font-size: 1.4rem;
			position: relative;
			display: block;
			background: #014099;
			color: #fff;
			margin: 0 10px 20px 10px;
			border: 1px solid #014099;
		}
		a:hover{
			color: #014099;
			background: #fff;
			.scene-more-icon:before{
				right: 15px;
			}
		}
	}
	@include media(sp){
		.recruit-point-anker{
			display: block;
			width: auto;
			
			.scene-more-icon{
			}
			.scene-more-icon:before{
			}
			a,
			a:link,
			a:visited,
			a:active{
				width: 90%;
				margin: 0 auto 20px auto;
			}
			a:hover{
				.scene-more-icon:before{
				}
			}
		}
	}
	.senior-voice-header{
		width: 960px;
		margin: 0 auto 30px auto;
		.senior-voice-header-block{
			.senior-voice-header-block-txt{
				width: 50%;
				position: absolute;
				top: 50%;
				left: 40px;
				-ms-transform: translate(0%, -50%);
				-webkit-transform: translate(0%, -50%);
				transform: translate(0%, -50%);
				z-index: 10;
				h2{
					font-size: 2.4rem;
					color: #014099;
					font-weight: 500;
					margin: 0 0 20px 0;
				}
				strong{
					font-size: 1.6rem;
					font-weight: 600;
					color: #014099;
					color: #18384c;
					font-family: 'Asap',"Noto Sans JP";
					padding: 0 0 0 60px;
					display: block;
					span{
						font-style: italic;
						font-size: 1.3rem;
						font-weight: 600;
						display: block;
					}
				}
				p{
					font-size: 1.3rem;
					padding: 20px 0 0 60px;
					margin: 0;
				}
			}
			.senior-voice-header-block-img-pc{
				height: 300px;
				background: url(../img/ir-top-header.jpg) no-repeat right;
				background-size: contain;
				margin-bottom: 50px;
				@include  flex(wrap,center,center);
				-ms-flex-align: center;
				align-items: center;
				position: relative;
			}
			.senior-voice-header-block-img-sp{
			}
		}
	}
	@include media(sp){
		.senior-voice-header{
			width: 100%;
			.senior-voice-header-block{
				.senior-voice-header-block-txt{
					width: 100%;
					padding: 0 10px;
				    position: absolute;
					top: auto;
					left: 0px;
					-ms-transform: translate(0%, 0);
					-webkit-transform: translate(0%, 0);
					transform: translate(0%, 0);
					z-index: 10;
					bottom: 0;
					h2{
						font-size: 2rem;
						color: #014099;
						font-weight: 500;
						margin: 0 0 10px 0;
					}
					strong{
						font-size: 1.6rem;
						font-weight: 600;
						color: #014099;
						color: #18384c;
						font-family: 'Asap',"Noto Sans JP";
						padding: 0 0 0 0;
						display: block;
						span{
							font-style: italic;
							font-size: 1.3rem;
							font-weight: 600;
							display: block;
						}
					}
					p{
						font-size: 1.3rem;
						padding: 10px 0 0 0;
						margin: 0;
					}
				}
				.senior-voice-header-block-img-pc{
					background-image: none !important;
					height:auto;
				}
				.senior-voice-header-block-img-sp{
					width: 100%;
					height: 500px;
					background-size: contain;
					background-repeat: no-repeat;
				}
				.senior-voice-header-block-img-sp:after{
					content: '';
					display: block;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					overflow:hidden;
					width: 100%;
					background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 60%);
				}
			}
		}
	}
	#voice-block{
		width: 960px;
		margin: 0 auto 30px auto;
		@include flex(wrap,center,space-between);
		.voice-block-interview{
			width: 640px;
			padding: 0 60px 0 0 ;
			.voice-block-interview-ttl{
				margin-bottom: 50px;
				img{
					width: 210px;
				}
			}
			.voice-block-interview-text-wrap{
				margin-bottom: 50px;
			}
			.voice-block-interview-text-img{
				padding: 20px 10px 10px 10px;
			}
			h3{
				font-size: 1.8rem;
				font-weight: 500;
				padding: 0 0 0 40px;
				margin: 0 0 30px 0;
				position: relative;
			}
			h3:before{
				content: " ";
				display: block;
				height: 1px;
				width: 30px;
				background: #18384c;
				position: absolute;
				top: 50%;
				left: 0;
				z-index: 1;
				margin-top: 1px;
			}
			.voice-block-interview-text{
				font-size: 1.3rem;
			}
		}
		.voice-block-schedule{
			width: 260px;
			.voice-block-schedule-ttl{
				background: #014099;
				padding: 10px 20px;
				margin-bottom: 20px;
				h4{
					font-family: 'Asap',"Noto Sans JP";
					font-weight: bold;
					color: #fff;
					margin: 0;
					font-size: 2.3rem;
					line-height: 1;
				}
				span{
					margin: 0;
					color: #fff;
					font-size: 1.3rem;
				}
			}
			.voice-block-schedule-text-wrap{
				padding: 20px 0;
				border-bottom: 1px solid #dcdcdc;
				strong{
					color: #f7a017;
					font-size: 1.6rem;
					padding: 0 0 12px 0;
				}
				.voice-block-schedule-text{
					font-size: 1.3rem;
					color: #18384c;
				}
			}
		}
	}
	@include media(sp){
		#voice-block{
			width: auto;
			margin: 0 auto 30px auto;
			display: block;
			.voice-block-interview{
				width: auto;
				padding: 0 10px;
				.voice-block-interview-ttl{
					margin-bottom: 20px;
					img{
						width: 200px;
					}
				}
				.voice-block-interview-text-wrap{
					margin-bottom: 20px;
				}
				h3{
					padding: 0 0 0 30px;
					margin: 0 0 10px 0;
				}
				h3:before{
					width: 20px;
				}
				.voice-block-interview-text{
					font-size: 1.3rem;
					line-height: 1.8;
				}
			}
			.voice-block-schedule{
				width: auto;
				padding: 0 10px;
				.voice-block-schedule-ttl{
					background: #014099;
					padding: 10px 20px;
					margin-bottom: 10px;
					h4{
						font-family: 'Asap',"Noto Sans JP";
						font-weight: bold;
						color: #fff;
						margin: 0;
						font-size: 2.3rem;
						line-height: 1;
					}
					span{
						margin: 0;
						color: #fff;
						font-size: 1.3rem;
					}
				}
				.voice-block-schedule-text-wrap{
					padding: 20px 0;
					border-bottom: 1px solid #dcdcdc;
					strong{
						color: #f7a017;
						font-size: 1.6rem;
						padding: 0 0 12px 0;
					}
					.voice-block-schedule-text{
						font-size: 1.3rem;
						color: #18384c;
					}
				}
			}
		}
	}
}



@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

