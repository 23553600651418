body.common.page-template-page-product-under-page{
	#main-area{
		width: 1200px;
		margin: 0 auto;
		position: relative;
		height: 320px;
		padding: 0 40px;
		img{
			float: right;
			-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
		}
		@include media(sp){
			img{
				float: right;
				-moz-box-shadow: 0px 0px 3px 3px rgba(20, 133, 207, 0.1);
				-webkit-box-shadow: 0px 0px 3px 3px rgba(20, 133, 207, 0.1);
				-ms-box-shadow: 0px 0px 3px 3px rgba(20, 133, 207, 0.1);
				box-shadow: 0px 0px 3px 3px rgba(20, 133, 207, 0.1);
			}
		}
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			h1{
				color: #18384c;
				font-size: 3.4rem;
				font-weight: 500;
				margin: 0 0 20px 0;
				font-family: 'Asap',"Noto Sans JP";
			}
			p{
				margin: 0;
				font-weight: 500;
				color: #fff;
				font-size: 1.6rem;
				width: auto;
				background: #014099;
				padding: 5px 10px;
				margin: 0 0 3px 0;
				display: inline-block;
			}
		}
	}
	@include media(sp){
		#main-area{
			width: auto;
			height: auto;
			padding: 0;
			// display: flex;
			// flex-direction: column;
			img{
				float: none;
				width: 100%;
				// order: 1;
				margin: 0;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				-ms-box-shadow: none;
				box-shadow: none;
			}
			.main-area-ttl{
				// order: 2;
				// position: static;
				// -ms-transform: translate(0, 0);
				// -webkit-transform: translate(0, 0);
				// transform: translate(0, 0);
				left: 10px;
				margin-top: 0;
				width: 95%;
				h1{
					color: #014099;
					font-size: 2rem;
					font-weight: 500;
					margin: 0 0 20px 0;
				}
				p{
					font-weight: 400;
					line-height: 2;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background-image: linear-gradient(90deg, rgba(255, 255, 255, 1)30%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0.15) 70%, transparent);
		}
	}
}

body.common.page-template-page-product{
	@include media(sp){
		#main-area{
			width: auto;
			height: auto;
			padding: 0 10px;
			// display: flex;
			// flex-direction: column;
			img{
				width: 100%;
				margin: 0;
			}
		}
	}
}

body.common.page-template-page-product,
body.common.page-template-page-product-series,
body.common.page-template-page-product-under-page{
	.scene-block-area{
		padding: 50px 60px 100px 50px;
		min-width: 1200px;
		h2{
			text-align: center;
			font-weight: 500;
			font-size: 2.6rem;
			color: #19a8c2;
		}
		.scene-block-list-wrap{
			overflow-x: scroll;
		}
		.scene-block-list-wrap::-webkit-scrollbar {
			width: 10px;
		}
		.scene-block-list-wrap::-webkit-scrollbar {
			height: 10px;
		}

		.scene-block-list-wrap::-webkit-scrollbar-track {
			background-color: #e4e4e4;
		}

		.scene-block-list-wrap::-webkit-scrollbar-thumb {
			background-color: #19a8c2;
		}
		.scene-block-list{
			@include flex(inherit,left,space-between);
			@include trans;
			-ms-flex-align: baseline;
			align-items: baseline;
			align-items: stretch;
			width: 100%;
			padding: 10px 0 40px 0;
			a,
			a:link,
			a:visited,
			a:active{
				@include flex(inherit,left,space-between);
				align-items: stretch;
			}
			a:hover{
			}
			.scene-block{
				width: 170px;
				height: auto;
				border: 1px solid #19a8c2;
				border-radius: 10px;
				padding: 17px 20px 30px 20px;
				text-align: center;
				position: relative;
				z-index: 10;
				cursor: pointer;
				margin: 0 10px 0 0;
				@include flex(wrap,center,center);
				-ms-flex-align: center;
				align-items: center;
				span{
				}
				.scene-img{
					height: 70px;
					img{
						margin: 0 auto 0 auto;
						// width: 80px;
						height: 70px;
						// min-width: 50px;
					}
				}
				.scene-ttl{
					height: 40%;
					display: block;
					font-size: 1.4rem;
					font-weight: 500;
					color: #19a8c2;
					@include flex(wrap,center,center);
					-ms-flex-align: center;
					align-items: center;
				}
			}
			.scene-arr{
				position: absolute;
				z-index: 5;
				bottom: -20px;
				margin-left: 0;
				width: 40px;
				height: 20px;
				border-radius: 0 0 40px 40px;
				background: #19a8c2;
				.fas{
					color: #fff;
				}
			}
		}
	}

	@include media(sp){
		.scene-block-area{
			padding: 10px 0;
			min-width: auto;
			h2{
				padding: 0 10px;
				margin: 0;
				font-size: 2rem;
			}
			.scene-block-list-wrap{
				overflow: scroll;
			}
			.scene-block-list{
				@include flex(nowrap,left,space-between);
				// min-width: 1200px;
				padding: 30px 10px;
				.scene-block{
					width: 130px;
					height: auto;
					.scene-ttl{
						margin-bottom: 10px;
					}
				}
			}
		}
	}
	.scene-select-wrap{
		position: relative;
		padding: 60px 0;
		margin: 0 0 40px 0;
	}
	.scene-select-wrap:after{
		content: "";
		width: 30%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: #e8f6f9;
	}
	@include media(sp){
		.scene-select-wrap{
			padding: 20px 0;
			margin: 30px 0;
		}
		.scene-select-wrap:after{
			width: 50%;
		}
	}
	.scene-select{
		width: 1200px;
		margin: 0 auto;
		position :relative;
		z-index: 10;
		.scene-ttl{
			padding: 0 0 70px 120px;
			position: relative;
			img{
				top: 0;
				left: 0;
				position: absolute;
				width: 90px;
			}
			h2{
				margin: 0 0 25px 0;
				font-size: 2rem;
				span{
					font-size: 2.8rem;
					color: #19a8c2;
				}
			}
			p{
				margin: 0;
				font-size: 1.3rem;
			}
		}
		.scene-select-block{
			@include flex(wrap,center,space-between);
			@include trans;
			-ms-flex-align: center;
			align-items: center;
			margin-bottom: 30px;
			.scene-detail{
				width: 440px;
				padding: 0 40px 0 0;
				.product-name{
					color: #7ab5c0;
					font-size: 1.4rem;
					font-weight: 400;
					margin: 0 0 4px 0;
					img{
						width: 60px;
					}
				}
				.product-txt{
					margin: 25px 0 30px 0;
				}
				span.scene-cat{
					line-height: 0;
					border: 1px solid #19a8c2;
					border-radius: 3px;
					padding: 3px 5px;
					color: #19a8c2;
					font-size: 1.2rem;
				}
				h3{
					margin: 0 0 10px 0;
					font-size: 2rem;
					font-weight: 500;
				}
				.scene-more{
					color: #014099;
					position: relative;
					padding: 0 50px 10px 0;
					border-bottom: 1px solid #014099;
				}
				.scene-more-icon{
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 10px;
					top: 50%;
					margin-top: -5px;
				}
				.scene-more-icon:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #d2a100;
					border-radius: 5px;
					position: absolute;
					right: -7px;
					top: 50%;
					margin-top: -4px;
					@include trans;
				}
				.scene-more:hover{
					.scene-more-icon:before{
						right: 15px;
					}
				}
			}
			.scene-img{
				border-radius: 6px 0 0 6px;
				width: 760px;
				height: 280px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	@include media(sp){
		.scene-select-block{
			display: block;
			margin-bottom: 50px;
			.scene-detail{
				width: auto;
				padding: 0 10px 30px 10px;
				.product-txt{
					margin: 20px 0 20px 0;
				}
			}
			.scene-img{
				width: auto;
				border-radius: 0;
			}
		}
	}
	}

	@include media(sp){
		.scene-select{
			width: auto;
			.scene-ttl{
				padding: 0 10px 30px 10px;
				img{
					left: 10px;
				}
				h2{
					padding: 30px 0 30px 100px;
					margin: 0;
					font-size: 1.8rem;
					span{
						font-size: 2.5rem;
						color: #19a8c2;
					}
				}
				p{
					padding-top: 20px;
				}
			}
		}
	}
	// .scene-select:after{
	// 	content: "";
	// 	width: 10px;
	// 	height: 100%;
	// 	display: block;
	// 	background-color: #e8f6f9;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 50%;
	// 	-ms-transform: translate(-50%, 0);
	// 	-webkit-transform: translate(-50%, 0);
	// 	transform: translate(-50%, 0);
	// }
	#wh-paper{
		display: none;
	}
}
body.common.page-template-page-product-under-page{
	.feature-point-wrap{
		width: 1200px;
		margin: 0 auto;
		.feature-point{
			border-bottom: 1px solid #adadad;
			border-top: 1px solid #adadad;
			background: #f3f9fd;
			width: 700px;
			margin: 50px auto 50px auto;
			padding: 40px 0;
			ul{
				padding: 0 0 0 90px;
				margin: 0;
				list-style: none;
				li{
					font-weight: 400;
					position: relative;
					padding: 0 0 10px 30px;
					span{
						position: absolute;
						top: 0;
						left: 0;
						font-style: italic;
						font-weight: 500;
					}
				}
			}
		}
	}
	@include media(sp){
		.feature-point-wrap{
			width: auto;
			padding: 0 10px;
			.feature-point{
				width: auto;
				padding: 20px 30px;
				ul{
					padding: 0;
					li{
						font-weight: 400;
						position: relative;
						padding: 0 0 10px 30px;
						span{
							position: absolute;
							top: 0;
							left: 0;
							font-style: italic;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
	.product-detail-link{
		width: 1200px;
		margin: 0 auto;
		.product-detail-link-wrap{
			@include flex(wrap,space-between,space-between);
			-ms-flex-align: center;
			align-items: center;
			.product-detail-link-ttl{
				width: 10%;
				padding: 15px 30px 15px 0;
				p{
					margin: 0;
					color: #18384c;
					font-size: 1.6rem;
				}
			}
			.product-detail-links{
				width: 90%;
				padding: 15px 0 15px 30px;
				@include flex(wrap,left,space-between);
				border-left: 1px dotted #333;
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					color: #19a8c2;
					padding: 0 40px 15px 0;
					.fas{
						padding-right: 10px;
					}
				}
				a:hover{
				}
			}
		}
	}
	@include media(sp){
		.product-detail-link{
			width: auto;
			padding: 0 10px;
			.product-detail-link-wrap{
				display: block;
				.product-detail-link-ttl{
					width: 100%;
					padding: 15px 30px 15px 15px;
					border-bottom: 1px dotted #333;
					p{
						margin: 0;
						color: #18384c;
						font-size: 1.6rem;
					}
				}
				.product-detail-links{
					width: 100%;
					padding: 15px 10px;
					display: block;
					border-left: none;
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						color: #19a8c2;
						padding: 0 0 15px 0;
						.fas{
							padding-right: 10px;
						}
					}
					a:hover{
					}
				}
			}
		}
	}
	.product-disc{
		width: 1200px;
		margin: 110px auto 100px auto;
		.product-disc-ttl{
			margin: 0 0 45px 0;
			position: relative;
			z-index: 5;
			text-align: center;
			font-weight: 400;
			color: #18384c;
			span{
				position: absolute;
				top: -40px;
				left: 50%;
				z-index: 10;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
				color: #edf4f8;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 4rem;
			}
		}
		.product-disc-block{
			width: 860px;
			margin: 0 auto 20px auto;
			-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			.product-disc-block-inner{
				padding: 0 50px 50px 50px;
			}
			.product-disc-block-inner:first-child{
				padding-top: 50px;
			}
			.font13{
				font-size: 1rem;
			}
			h2{
				margin: 0;
				font-size: 2.2rem;
				font-weight: 600;
				padding: 40px 0 20px 0;
			}
			h2:first-child{
				padding-top: 0;
			}
			h3{
				margin: 0;
				font-size: 2rem;
				font-weight: 600;
				padding: 0 0 18px 0;
				img{
					width: 2.5rem;
					margin-right: 10px;
				}
			}
			h4{
				margin-top: 0;
				font-size: 1.6rem;
			}
			p{
				margin: 0;
				font-size: 1.3rem;
				font-weight: 300;
			}
			img.icon10{
				display: inline;
				width: 70px;
				margin: 0 10px 0 0;
				vertical-align: middle;
			}
			table{
				border-collapse: separate;
				border-spacing: 5px;
				tbody{

				}
				tr{
					th,td{
						font-size: 1.3rem;
						font-weight: normal;
					}
					th{
						width: 20%;
						background: #d4f0f8;
						padding: 20px 5px;
					}
					td{
						width: 80%;
						border: 1px solid #f0f4f9;
						padding: 20px 30px 20px 30px;
					}
				}
				tr:nth-child(even){
					td{
						border: none;
						background: #f0f4f9;
					}
				}
			}
			.couttion-list{
				margin: 10px 0 0 0;
				padding: 0 0 0 1rem;
				li{
					font-size: 1.1rem;
				}
			}
			.product-disc-block-detail{
				padding: 30px 0 20px 0;
				h3{
					margin: 50px 0 0 0;
					padding: 0;
				}
				video{
					width: 520px;
					height: auto;
					display: block;
					margin: 40px auto 0 auto;
				}
				img{
					width: auto;
					height: auto;
					display: block;
					// margin: 20px auto 0 auto;
					margin: 0 auto 0 auto;
				}
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
					@include hover;
				}
			}
			.product-disc-block-detail-inner{
				@include flex(nowrap,space-between,space-between);
				margin: 0 0 0 0;
				-ms-flex-align: baseline;
				align-items: baseline;
				.product-disc-block-sep{
					padding: 10px;
					width: 100%;
					display: flex;
					flex-direction: column;
					img{
						width: 100%;
						margin: 20px 0 0 0;
					}
					img.icon10{
						display: inline;
						width: 60px;
						margin: 0 10px 0 0;
						vertical-align: middle;
					}
					h4{
						flex: 1;
						height: 100%;
						min-height: 0%;
						margin: 0 0 10px 0;
					}
				}
				// フロー表
			}
			.product-disc-block-flow-wrap{
				@include flex(nowrap,left,space-between);
				padding: 20px 0;
				.product-disc-block-flow{
					width: 100%;
					padding: 6px;
					h5{
						margin: 0;
						font-weight: 500;
						font-size: 1.2rem;
						padding-bottom: 5px;
					}
				}
			}
			@include media(sp){

				.product-disc-block-detail-inner{
					display: block;
					.product-disc-block-sep{
						padding: 0;
						width: auto;
						img{
							margin: 0;
						}
					}
					h4{
						img{
							width: 25px;
						}
					}
				}
				.product-disc-block-flow-wrap{
					@include flex(wrap,left,space-between);
					padding: 20px 0;
					.product-disc-block-flow{
						width: 50%;
						padding: 6px;
						h5{
							margin: 0;
							font-weight: 500;
							font-size: 1.2rem;
						}
					}
				}
			}
		}
		@include media(sp){
			.product-disc-block{
				h2{
					font-size: 2rem;
					padding: 30px 0 10px 0;
				}
				h2:first-child{
					padding-top: 0;
				}
				h3{
					font-size: 1.8rem;
					img{
					}
				}
				table{
					border-collapse: initial;
					border-spacing: 0;
					display: block;
					tbody{
						display: block;
					}
					tr{
						display: block;
						th,td{
							display: block;
							font-size: 1.3rem;
							font-weight: normal;
							text-align: left;
							margin: 6px 0;
						}
						th{
							width: 100%;
							background: #d4f0f8;
							padding: 14px 20px 14px 20px;
							margin: 20px 0 6px 0;
						}
						td{
							width: 100%;
							border: 1px solid #f0f4f9;
							padding: 14px 20px 14px 20px;
						}
					}
					tr:nth-child(even){
						td{
							border: 1px solid #f0f4f9;
							background: #fff;
						}
					}
				}
			}
		}
		.document-dl{
			display: block;
			font-size: 1.7rem;
			color: #19a8c2;
			border: 1px solid #19a8c2;
			border-radius: 10px;
			width: 240px;
			text-align: center;
			margin: 0 auto;
			padding: 18px 0;
			font-weight: 300;
			opacity: 1;
			filter: alpha(opacity=100);
		}
		.document-dl:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
		}

		.product-btn{
			@include flex(wrap,center,space-between);
			width: 960px;
			margin: 0 auto 30px auto;
			padding: 20px 0 0 0;
			.scene-more-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 15px;
				top: 50%;
				margin-top: -1px;
			}
			.scene-more-icon:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: -7px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
			a,
			a:link,
			a:visited,
			a:active{
				text-align: center;
				width: auto;
				padding: 16px 40px 16px 14px;
				font-size: 1.4rem;
				position: relative;
				display: block;
				background: #014099;
				color: #fff;
				margin: 0 10px 20px 10px;
				border: 1px solid #014099;
			}
			a:hover{
				color: #014099;
				background: #fff;
				.scene-more-icon:before{
					right: 15px;
				}
			}
		}
		@include media(sp){
			.product-btn{
				display: block;
				width: auto;
				.voice-btn{
					.scene-more-icon{
					}
					.scene-more-icon:before{
					}
					a,
					a:link,
					a:visited,
					a:active{
						width: 90%;
						margin: 0 auto 20px auto;
					}
					a:hover{
						.scene-more-icon:before{
						}
					}
				}
			}
		}
	}
	.product-disc.smile-privacy{
		margin: 50px auto 100px auto;
		.product-disc-block{
			width: 1000px;
		}
	}
	@include media(sp){
	.product-disc.smile-privacy{
		margin: 50px auto 100px auto;
		.product-disc-block{
			width: auto;
		}
	}
	}
	#feature-block.product-disc{
		.product-disc-block{
			h3{
				margin: 0;
				font-size: 2rem;
				font-weight: 600;
				padding: 0 0 18px 0;
			}
			p{
				margin: 0;
				font-size: 1.3rem;
				font-weight: 300;
			}
			ul{
				list-style: none;
				li{
					font-weight: 600;
					border-top: 1px solid #18384c;
					// border-bottom: none;
					padding: 20px 30px 20px 40px;
					position: relative;
				}
				li:before{
					content: "";
					width: 7px;
					height: 7px;
					display: block;
					background: #054099;
					border-radius: 5px;
					position: absolute;
					left: 20px;
					top: 50%;
					margin-top: -4px;
					@include trans;
				}
				li:last-child{
					border-bottom: 1px solid #18384c;
				}
			}
			img{
				width: 520px;
				height: auto;
				display: block;
				margin: 40px auto 0 auto;
			}
		}

	}
	@include media(sp){
		.product-disc{
			width: auto;
			margin: 50px auto 50px auto;
			padding: 0 10px;
			.product-disc-block{
				width: auto;
				.product-disc-block-inner{
					padding: 0 20px 20px 20px;
				}
				h3{
					padding: 0 0 10px 0;
				}
				p{
				}
				.product-disc-block-detail{
					video,
					img{
						margin: 20px auto 0 auto;
						width: 100%;
					}
				}
			}
		}
		#feature-block.product-disc{
			.product-disc-block{
				.product-disc-block-detail{
					img{
						margin: 20px auto 0 auto;
						width: 100%;
					}
				}
			}

		}
	}
	.scene-block-area-under{
		background: #f3f9fd;
		margin: 0 0 80px 0;
	}
	.product-cta{
		position:fixed;
		bottom: 0;
		left: 0;
		background: rgba(25,168,194, 0.9);
		width: 100%;
		text-align: center;
		@include flex;
		-ms-flex-align: center;
		align-items: center;
		z-index: 900;
		padding: 12px;
		.send-doc{
			border-radius: 6px;
			width: 180px;
			font-size: 1.5rem;
			padding: 10px;
			color: #18384c;
			background: #ffe941;
			margin-right: 10px;
			font-weight: 500;
		}
		.send-doc:hover{
			color: #18384c;
			background: #fff;
		}
		.contact{
			border-radius: 6px;
			width: 180px;
			font-size: 1.5rem;
			padding: 10px;
			color: #18384c;
			background: #fff;
			font-weight: 500;
			.far{
				display: none;
			}
		}
		.contact:hover{
			color: #18384c;
			background: #ffe941;
		}
		.tel{
			margin-left: 20px;
			color: #fff;
			font-size: 2.6rem;
			font-family: 'Asap',"Noto Sans JP";
			.fas{
				padding-right: 10px;
			}
		}
		.tel:hover{
			color: #ffe941;
		}
	}
	@include media(sp){
		.product-cta{
			padding: 8px 5px;
			.send-doc{
				border-radius: 6px;
				width: 39%;
				font-size: 1.4rem;
				margin-right: 1%;
				font-weight: 500;
			}
			.contact{
				border-radius: 6px;
				width: 39%;
				font-size: 1.4rem;
				font-weight: 500;
				.far{
					display: none;
				}
			}
			.tel{
				width: 10%;
				margin-left: 1%;
				color: #fff;
				font-size: 2rem;
				padding: 6px;
				color: #fff;
				background: #054099;
				border-radius: 6px;
				text-align: center;
				.fas{
					padding: 0;
				}
				span{
					display: none;
				}
			}
		}
	}
}

@include media(sp){

}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

