body.common{
	header.sub-nav{
		min-width: 1200px;
		background: #19a8c2;
		color: #fff;
		padding: 20px 40px;
		@include flex(wrap,space-between,space-between);
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		h1{
			margin: 0;
			font-weight: 300;
			font-size: 2rem;
			@include flex(wrap,start,space-between);
			-ms-flex-align: center;
			align-items: center;
			svg{
				width: 30px;
				margin-right: 20px;
			}
		}
		@include media(sp){
			h1{
				font-size: 1.6rem;
				align-items: center;
				svg{
					width: 20px;
					margin-right: 10px;
				}
			}
		}
		.sub-nav-btn-wrap{
			@include flex(wrap,start,space-between);
			@include trans;
			-ms-flex-align: center;
			align-items: center;
			cursor: pointer;
			opacity: 1;
			filter: alpha(opacity=1);
			.sub-nav-btn{
				width: 30px;
				height: 30px;
				border: 1px solid #fff;
				border-radius: 30px;
				position: relative;
				margin-left: 10px;
			}
			@include media(sp){
			}
			p{
				margin: 0;
			}
			.sub-nav-btn:before,
			.sub-nav-btn:after{
				content: "";
				width: 10px;
				height: 2px;
				display: block;
				background-color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
			}
			.sub-nav-btn:before{
				margin-top: -3px;
			}
			.sub-nav-btn:after{
				margin-top: 2px;
			}
		}
		.sub-nav-btn-wrap:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
		.sub-nav-all{
			display: none;
			position: absolute;
			top: 70px;
			right: 30px;
			z-index: 50;
			-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			padding: 40px 40px 10px 40px;
			background: #fff;
			.sub-nav-all-wrap{
				@include flex(wrap,start,space-between);
				.sub-nav-all-block{
					padding-left: 50px;
					.sub-nav-all-menu-icon{
						display: block;
						height: 1px;
						width: 20px;
						background: #d2a100;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -1px;
					}
					.sub-nav-all-menu-icon:before{
						content: "";
						width: 7px;
						height: 7px;
						display: block;
						background: #d2a100;
						border-radius: 5px;
						position: absolute;
						right: -7px;
						top: 50%;
						margin-top: -3px;
						@include trans;
					}
					h4{
						color: #18384c;
						margin: 0 0 20px 0;
						font-size: 1.2rem;
						font-weight: 700;
						a,
						a:link,
						a:visited,
						a:active{
							padding: 0 0 0 40px;
							position: relative;
						}
						a:hover{
							.sub-nav-all-menu-icon{
							}
							.sub-nav-all-menu-icon:before{
								right: 20px;
							}
						}
					}
					ul{
						padding-bottom: 40px;
					}
					li{
						padding-bottom: 6px;
						a,
						a:link,
						a:visited,
						a:active{
							color: #18384c;
							font-size: 1.2rem;
							padding: 0 0 0 15px;
							display: block;
							position: relative;
						}
						a:hover{
							opacity: 0.8;
							filter: alpha(opacity=80);
						}
						a:before{
						content: "";
						width: 7px;
						height: 7px;
						display: block;
						background: #d2a100;
						border-radius: 5px;
						position: absolute;
						left: 0px;
						top: 50%;
						margin-top: -3px;
						@include trans;
					}
					}
				}
				.sub-nav-all-block:first-child{
					padding: 0;
				}
				@include media(sp){
					.sub-nav-all-block{
						padding: 0;
					}
				}
			}
			@include media(sp){
				.sub-nav-all-wrap{
					// display: block;
				}
			}
		}
		.sub-nav-all:before{
			content: "";
			position: absolute;
			top: -20px;
			right: 50px;
			background: transparent;
			display: block;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			border-left: 20px solid transparent;
		}
		@include media(sp){
			.sub-nav-all{
				width: 90%;
				right: 10px;
				padding: 30px 30px 10px 30px;
				height: 80vh;
					overflow: scroll;
				.sub-nav-all-wrap{
					display: block;
				}
			}
		}
	}
	@include media(sp){
		header.sub-nav{
			min-width: auto;
			padding: 10px;
		}
	}
	#main-area{
		width: 1200px;
		margin: 0 auto;
		position: relative;
		height: 440px;
		padding: 0 40px;
		img{
			float: right;
		}
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 400;
				line-height: 32px;
				font-size: 1.7rem;
				width: 300px;
			}
		}
	}

	@include media(sp){
		#main-area{
			height: auto;
			width: auto;
			padding: 10px;
			display: flex;
			flex-direction: column;
			img{
				width: 80%;
				margin: 0 auto;
				float: none;
				order: 1;
			}
			.main-area-ttl{
				order: 2;
				position: static;
				-ms-transform: translate(0, 0);
				-webkit-transform: translate(0, 0);
				transform: translate(0, 0);
				margin-top: 20px;
				h1{
					color: #014099;
					font-size: 2rem;
					font-weight: 500;
					margin: 0 0 20px 0;
				}
				p{
					font-weight: 400;
					line-height: 2;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
	}
}
// body.page-template-page-product,
// body.page-template-page-ir-top,
// body.page-template-page-ir-news,
// body.page-template-page-ir-basic,
// body.page-template-page-product-series,
// body.page-template-page-product-under-page,
// body.page-template-page-support
body.header-under{
	#page.show{
		position: relative;
		header.sub-nav{
			position: fixed;
			top: 0;
			left: 0;
			z-index: 950;
			width: calc(100% - 70px);
			h1{
				svg{
				}
			}
			@include media(sp){
				h1{
					svg{
					}
				}
			}
			.sub-nav-btn-wrap{
				.sub-nav-btn{
				}
				@include media(sp){
				}
				p{
				}
				.sub-nav-btn:before,
				.sub-nav-btn:after{
				}
				.sub-nav-btn:before{
				}
				.sub-nav-btn:after{
				}
			}
			.sub-nav-btn-wrap:hover{
			}
			.sub-nav-all{
				.sub-nav-all-wrap{
					.sub-nav-all-block{
						.sub-nav-all-menu-icon{
						}
						.sub-nav-all-menu-icon:before{
						}
						h4{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
								.sub-nav-all-menu-icon{
								}
								.sub-nav-all-menu-icon:before{
								}
							}
						}
						ul{
						}
						li{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							a:before{
						}
						}
					}
					.sub-nav-all-block:first-child{
					}
				}
			}
			.sub-nav-all:before{
			}
		}
		@include media(sp){
			header.sub-nav{
				width: calc(100% - 50px);
			}
		}
	}
}

@include media(sp){

}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

