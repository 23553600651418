@charset "UTF-8";
#masthead{
	width: 100%;
	min-width: 1200px;
	border-bottom: 1px solid #fff;
	max-height: 80px;
	padding: 27px 0;
	position: relative;
	background: #fff;
	.nav-block{
		@include flex(wrap,space-between,space-between);
		-ms-flex-align: center;
		align-items: center;
		.site-branding{
			.site-title{
				margin: 0;
				padding: 0 0 0 30px;
				a,
				a:link,
				a:visited,
				a:active{
					opacity: 1;
					filter: alpha(opacity=100);
					img{
						@include trans;
						width: 333px;
						height: auto;
					}
					@include media(sp){
						img{
							width: 250px;
							height: auto;
						}
					}
				}
				a:hover{
					opacity: 0.8;
					filter: alpha(opacity=80);
				}
			}
			@include media(sp){
				.site-title{
					padding: 6px 0 0 10px;
				}
			}
		}
		#site-navigation{
			padding: 0 120px 0 0;
			.menu-menu-1-container{
				#primary-menu{
					list-style: none;
					@include flex(wrap,start,space-between);
					-ms-flex-align: center;
					align-items: center;
					padding: 0;
					margin: 0;
					a,
					a:link,
					a:visited,
					a:active{
						color: #18384c;
						display: block;
						padding: 0 20px;
						position: relative;
					}
					a:hover{
						opacity: 0.8;
						filter: alpha(opacity=80);
					}
					a:after{
						content: "";
						width: 1px;
						height: 1em;
						display: block;
						background-color: #18384c;
						position: absolute;
						top: 50%;
						right: 0;
						-ms-transform: translate(0, -50%);
						-webkit-transform: translate(0, -50%);
						transform: translate(0, -50%);
					}
					li:last-child{
						a:after{
							display: none;
						}
					}
				}
			}
		}
		@include media(sp){
			#site-navigation{
				display: none;
			}
		}
	}
	#drop-nav-block{
		display: none;
		background: #014099;
		height: 100vh;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 998;
		padding: 27px 0 0 0;
		min-width: 1200px;
		// opacity: 0;
		// filter: alpha(opacity=0);
		.drop-header{
			margin: 0;
			padding: 0 0 0 30px;
			@include flex(wrap,space-between,space-between);
			-ms-flex-align: center;
			align-items: center;
			a,
			a:link,
			a:visited,
			a:active{
				img{
					width: 333px;
				}
			}
		}
		@include media(sp){
			.drop-header{
				padding: 0 0 0 10px;
				display: block;
				a,
				a:link,
				a:visited,
				a:active{
					img{
						width: 150px;
					}
				}
				a:hover{
				}
			}
		}
		.drop-header-txt{
			padding: 0 120px 0 0;
			@include flex;
			-ms-flex-align: center;
			align-items: center;
			p{
				margin: 0;
				color: #fff;
				padding-right: 15px;
			}
			a,
			a:link,
			a:visited,
			a:active{
				font-size: 2.6rem;
				font-family: 'Asap',"Noto Sans JP";
				.fas{
					padding-right: 10px;
				}
			}
			a:hover{
				opacity: 0.8;
				filter: alpha(opacity=80);
			}
		}
		@include media(sp){
			.drop-header-txt{
				display: block;
				padding: 30px 0 0 0;
			}
		}
		a,
		a:link,
		a:visited,
		a:active{
			color: #fff;
			display: block;
		}
		a:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
		.drop-menu-wrap{
			width: 1040px;
			padding: 0 20px 210px 20px;
			margin: 70px auto 0 auto;
			@include flex(wrap,space-between,space-between);
			overflow: scroll;
			height: 100%;
		}
		@include media(sp){
			.drop-menu-wrap{
				display: block;
				width: auto;
				padding: 0 20px 300px 20px;
				margin: 30px auto 0 auto;
			}
		}
		.drop-menu-left,
		.drop-menu-center,
		.drop-menu-right{
			width: 33.3333333333%;
			.drop-menu{
				margin-bottom: 30px;
			}
		}
		@include media(sp){
			.drop-menu-left,
			.drop-menu-center,
			.drop-menu-right{
				width: 100%;
				.drop-menu{
					margin-bottom: 30px;
				}
			}
		}
		.drop-menu-left{
			padding-right: 30px;
		}
		.drop-menu-center{
			padding-right: 30px;
		}
		@include media(sp){
			.drop-menu-left{
				padding-right: 0;
			}
			.drop-menu-center{
				padding-right: 0;
			}
		}
		.drop-menu{
			border: 1px solid #4e7ab8;
			padding: 45px 39px;
			h2{
				margin: 0;
				color: #fff;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 3rem;
				span{
					display: block;
					@include fonts-base;
					font-size: 1.4rem;
					font-weight: 300;
				}
			}
			.drop-menu-block{
				list-style: none;
				padding: 0;
				margin: 40px 0 0 0;
				li{
					a,
					a:link,
					a:visited,
					a:active{
						color: #fff;
						display: block;
						font-family: 'Asap',"Noto Sans JP";
						font-size: 2rem;
						position: relative;
						padding: 0 0 0 40px;
						.drop-menu-icon{
							display: block;
							height: 1px;
							width: 20px;
							background: #d2a100;
							position: absolute;
							left: 0;
							top: 50%;
							margin-top: -1px;
						}
						.drop-menu-icon:before{
							content: "";
							width: 7px;
							height: 7px;
							display: block;
							background: #d2a100;
							border-radius: 5px;
							position: absolute;
							right: -7px;
							top: 50%;
							margin-top: -4px;
							@include trans;
						}
					}
					a:hover{
						opacity: 0.8;
						filter: alpha(opacity=80);
						.drop-menu-icon{
						}
						.drop-menu-icon:before{
							right: 20px;
						}
					}
				}
				.drop-menu-block-child{
					list-style: none;
					padding: 30px 0 0 40px;
					margin: 0;
					li{
						padding-top: 20px;
						a,
						a:link,
						a:visited,
						a:active{
							color: #fff;
							display: block;
							font-family: 'Asap',"Noto Sans JP";
							font-size: 1.2rem;
						}
						a:hover{
							opacity: 0.8;
							filter: alpha(opacity=80);
						}
						.drop-menu-block-grandchild{
							list-style: none;
							padding: 0px 0 0 40px;
							margin: -10px 0 0 0;
							li{
								padding-top: 10px;
								a,
								a:link,
								a:visited,
								a:active{
									position: relative;
									color: #fff;
									display: block;
									font-family: 'Asap',"Noto Sans JP";
									font-size: 1.2rem;
									padding: 0;
								}
								a:hover{
									opacity: 0.8;
									filter: alpha(opacity=80);
								}
								a:before{
									content: "";
									width: 7px;
									height: 7px;
									display: block;
									background: #d2a100;
									border-radius: 5px;
									position: absolute;
									left: -20px;
									top: 50%;
									margin-top: -4px;
									@include trans;
								}
							}
						}
					}
					li.no-link{
						color: #fff;
						display: block;
						font-family: 'Asap',"Noto Sans JP";
						font-size: 1.2rem;
					}
					li:first-child{
						padding-top: 0;
					}
				}
			}
		}
		@include media(sp){
			.drop-menu{
				padding: 30px 20px;
				h2{
					span{
					}
				}
				.drop-menu-block{
					margin: 20px 0 0 0;
					li{
						a,
						a:link,
						a:visited,
						a:active{
							.drop-menu-icon{
							}
							.drop-menu-icon:before{
							}
						}
						a:hover{
							.drop-menu-icon{
							}
							.drop-menu-icon:before{
							}
						}
					}
					.drop-menu-block-child{
						padding: 20px 0 0 20px;
						li{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.drop-menu-block-grandchild{
								li{
									a,
									a:link,
									a:visited,
									a:active{
									}
									a:hover{
									}
									a:before{
									}
								}
							}
						}
						li.no-link{
						}
						li:first-child{
						}
					}
				}
			}
		}
		.drop-footer{
			position:absolute;
			bottom: 0;
			left: 0;
			background: #014099;
			padding: 30px 0 50px 0;
			width: 100%;
		    ul{
			    // position: absolute;
			    // bottom: 0;
			    // left: 50%;
			    // -ms-transform: translate(0, -50%);
			    // -webkit-transform: translate(0, -50%);
			    // transform: translate(0, -50%);
			    list-style: none;
				@include flex(wrap,center,space-between);
				a,
				a:link,
				a:visited,
				a:active{
					color: #fff;
					display: block;
					padding: 0 20px;
					position: relative;
				}
				a:hover{
					opacity: 0.8;
					filter: alpha(opacity=80);
				}
				a:after{
					content: "";
					width: 1px;
					height: 1em;
					display: block;
					background-color: #fff;
					position: absolute;
					top: 50%;
					right: 0;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
				li:last-child{
					a:after{
						display: none;
					}
				}
		    }
		}

		@include media(sp){
			.drop-footer{
				padding: 10px 0;
			}
		}
	}
	@include media(sp){
		#drop-nav-block{
			padding: 10px 0 0 0;
			min-width: auto;
		}
	}
	#humbergur-btn-block{
		background: #014099;
		position: absolute;
		top: 0px;
		right: 0px;
		z-index: 999;
		padding: 20px;
		cursor: pointer;
		width: 80px;
		height: 80px;
	}
	@include media(sp){
		#humbergur-btn-block{
			width: 50px;
			height: 50px;
		}
	}
	#humbergur-btn-wrap{
		@include center;
		display: block;
		margin: -5px 0 auto 0;
		align-items: center;
		width: 30px;
		height: 30px;
		#humbergur-btn{
			padding: 0;
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
			position: relative;
			span {
				display: block;
				width: 100%;
				height: 100%;
				line-height: 1;
				word-break: keep-all;
				position: relative;
			}
			span:before {
				content: "";
				margin: auto;
				width: 100%;
				height: 2px;
				display: block;
				background-color: #fff;
				transition: opacity .3s;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
			}
		}
		#humbergur-btn:after,
		#humbergur-btn:before {
			content: "";
			width: 100%;
			height: 2px;
			display: block;
			background-color: #fff;
			transition: transform .3s,width .3s;
			position: absolute;
			left: 0;
		}
		#humbergur-btn:after{
			bottom: 4px;
		}
		#humbergur-btn:before{
			top: 4px;
		}
	}
	@include media(sp){
		#humbergur-btn-wrap{
			width: 25px;
			height: 25px;
		}
	}
	.all{
		color: #fff;
		line-height:0;
		font-size: 1rem;
		position: absolute;
		bottom: -8px;
		left: 50%;
		-ms-transform: translate(-50%, 0);
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
	@include media(sp){
		.all{
			bottom: -6px;
		}
	}
}

@include media(sp){
	#masthead{
		min-width: auto;
		padding: 10px 0;
		min-height: 50px;
		max-height: 50px;
	}
}

#masthead.open{
	#humbergur-btn-block{
		position: fixed;
	}
	#humbergur-btn-wrap{
		z-index: 20;
		#humbergur-btn {
			background-color: transparent;
			span:before{
				 background-color: transparent;
			}
		}
		#humbergur-btn:before {
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			top: 13px;
		}
		#humbergur-btn:after {
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			bottom: 15px;
		}
		@include media(sp){
			#humbergur-btn:before {
				top: 11px;
			}
			#humbergur-btn:after {
				bottom: 12px;
			}
		}

	}
	#drop-nav-block{
		// display: block;
		// opacity: 1;
		// filter: alpha(opacity=100);
		.drop-header{
		}
		.drop-header-txt{
			p{
			}
			a,
			a:link,
			a:visited,
			a:active{
				.fas{
				}
			}
			a:hover{
			}
		}
		a,
		a:link,
		a:visited,
		a:active{
		}
		a:hover{
		}
		.drop-menu-wrap{
		}
		.drop-menu-left,
		.drop-menu-center,
		.drop-menu-right{
			.drop-menu{
			}
		}
		.drop-menu-left{
		}
		.drop-menu-center{
		}
		.drop-menu{
			h2{
				span{
				}
			}
			.drop-menu-block{
				li{
					a,
					a:link,
					a:visited,
					a:active{
						.drop-menu-icon{
						}
						.drop-menu-icon:before{
						}
					}
					a:hover{
						.drop-menu-icon{
						}
						.drop-menu-icon:before{
						}
					}
				}
				.drop-menu-block-child{
					li{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
						}
					}
					li:first-child{
					}
				}
			}
		}
	}
}

#page.show{
	#masthead{
		.nav-block{
			.site-branding{
				.site-title{
					a,
					a:link,
					a:visited,
					a:active{
						img{
						}
						@include media(sp){
							img{
							}
						}
					}
					a:hover{
					}
				}
				@include media(sp){
					.site-title{
					}
				}
			}
			#site-navigation{
				.menu-menu-1-container{
					#primary-menu{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
						}
						a:after{
						}
						li:last-child{
							a:after{
							}
						}
					}
				}
			}
			@include media(sp){
				#site-navigation{
				}
			}
		}
		#drop-nav-block{
			.drop-header{
			}
			@include media(sp){
				.drop-header{
					a,
					a:link,
					a:visited,
					a:active{
						img{
						}
					}
					a:hover{
					}
				}
			}
			.drop-header-txt{
				p{
				}
				a,
				a:link,
				a:visited,
				a:active{
					.fas{
					}
				}
				a:hover{
				}
			}
			@include media(sp){
				.drop-header-txt{
				}
			}
			a,
			a:link,
			a:visited,
			a:active{
			}
			a:hover{
			}
			.drop-menu-wrap{
			}
			@include media(sp){
				.drop-menu-wrap{
				}
			}
			.drop-menu-left,
			.drop-menu-center,
			.drop-menu-right{
				.drop-menu{
				}
			}
			@include media(sp){
				.drop-menu-left,
				.drop-menu-center,
				.drop-menu-right{
					.drop-menu{
					}
				}
			}
			.drop-menu-left{
			}
			.drop-menu-center{
			}
			@include media(sp){
				.drop-menu-left{
				}
				.drop-menu-center{
				}
			}
			.drop-menu{
				h2{
					span{
					}
				}
				.drop-menu-block{
					li{
						a,
						a:link,
						a:visited,
						a:active{
							.drop-menu-icon{
							}
							.drop-menu-icon:before{
							}
						}
						a:hover{
							.drop-menu-icon{
							}
							.drop-menu-icon:before{
							}
						}
					}
					.drop-menu-block-child{
						li{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
						}
						li:first-child{
						}
					}
				}
			}
			.drop-footer{
			    ul{
					a,
					a:link,
					a:visited,
					a:active{
						color: #fff;
					}
					a:hover{
					}
					a:after{
					}
					li:last-child{
						a:after{
						}
					}
			    }
			}

			@include media(sp){
				.drop-footer{
				}
			}
		}
		@include media(sp){
			#drop-nav-block{
			}
		}
		#humbergur-btn-block{
			position: fixed;
			width: 70px;
			height: 70px;
		}
		@include media(sp){
			#humbergur-btn-block{
				width: 50px;
				height: 50px;
			}
		}
		@include media(sp){
			#humbergur-btn-block{
			}
		}
		#humbergur-btn-wrap{
			#humbergur-btn{
				span {
				}
				span:before {
				}
			}
			#humbergur-btn:after,
			#humbergur-btn:before {
			}
			#humbergur-btn:after{
			}
			#humbergur-btn:before{
			}
		}
		@include media(sp){
			#humbergur-btn-wrap{
			}
		}
		.all{
		}
		@include media(sp){
			.all{
			}
		}
	}
}

#top{

	#page.show{
		#masthead{
			position: fixed;
			max-height: 70px;
			z-index: 500;
			top: 0;
			left: 0;
			.nav-block{
				.site-branding{
					.site-title{
						line-height: 0;
						a,
						a:link,
						a:visited,
						a:active{
							img{
							}
							@include media(sp){
								img{
								}
							}
						}
						a:hover{
						}
					}
					@include media(sp){
						.site-title{
						}
					}
				}
				#site-navigation{
					.menu-menu-1-container{
						#primary-menu{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							a:after{
							}
							li:last-child{
								a:after{
								}
							}
						}
					}
				}
				@include media(sp){
					#site-navigation{
					}
				}
			}
			#drop-nav-block{
				.drop-header{
				}
				@include media(sp){
					.drop-header{
						a,
						a:link,
						a:visited,
						a:active{
							img{
							}
						}
						a:hover{
						}
					}
				}
				.drop-header-txt{
					p{
					}
					a,
					a:link,
					a:visited,
					a:active{
						.fas{
						}
					}
					a:hover{
					}
				}
				@include media(sp){
					.drop-header-txt{
					}
				}
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
				}
				.drop-menu-wrap{
				}
				@include media(sp){
					.drop-menu-wrap{
					}
				}
				.drop-menu-left,
				.drop-menu-center,
				.drop-menu-right{
					.drop-menu{
					}
				}
				@include media(sp){
					.drop-menu-left,
					.drop-menu-center,
					.drop-menu-right{
						.drop-menu{
						}
					}
				}
				.drop-menu-left{
				}
				.drop-menu-center{
				}
				@include media(sp){
					.drop-menu-left{
					}
					.drop-menu-center{
					}
				}
				.drop-menu{
					h2{
						span{
						}
					}
					.drop-menu-block{
						li{
							a,
							a:link,
							a:visited,
							a:active{
								.drop-menu-icon{
								}
								.drop-menu-icon:before{
								}
							}
							a:hover{
								.drop-menu-icon{
								}
								.drop-menu-icon:before{
								}
							}
						}
						.drop-menu-block-child{
							li{
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
							}
							li:first-child{
							}
						}
					}
				}
				.drop-footer{
				    ul{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
						}
						a:after{
						}
						li:last-child{
							a:after{
							}
						}
				    }
				}

				@include media(sp){
					.drop-footer{
					}
				}
			}
			@include media(sp){
				#drop-nav-block{
				}
			}
			#humbergur-btn-block{
			}
			@include media(sp){
				#humbergur-btn-block{
				}
			}
			@include media(sp){
				#humbergur-btn-block{
				}
			}
			#humbergur-btn-wrap{
				#humbergur-btn{
					span {
					}
					span:before {
					}
				}
				#humbergur-btn:after,
				#humbergur-btn:before {
				}
				#humbergur-btn:after{
				}
				#humbergur-btn:before{
				}
			}
			@include media(sp){
				#humbergur-btn-wrap{
				}
			}
			.all{
			}
			@include media(sp){
				.all{
				}
			}
		}
		@include media(sp){
			#masthead{
				min-width: auto;
				padding: 10px 0;
				min-height: 50px;
				max-height: 50px;
			}
		}
	}

}

@include media(sp){

}

// SMP
@media all and (max-width: $brakeWPC) {

}
// SMP
// tab
// @media all and (min-width: $brakeWSP) {
// 	body.common{
// 	}
// }
// tab
// PC
@media all and (min-width: $brakeWPC) {

}
// PC
