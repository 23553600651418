body.common.page-template-page-ir-library,
body.common.page-template-page-ir-news,
body.common.page-template-page-ir-basic,
body.common.page-template-page-ir-top{
	header.sub-nav{
		background: #0cbb8c;
	}
	#main-area{
		height: 240px;
		background: url(../img/ir-top-header.jpg) no-repeat right;
		background-size: contain;
		margin-bottom: 50px;
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 50%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.7) 100%);
	}
	@include media(sp){
		#main-area{
			height: 50vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
				background-image: url(../img/ir-header-sp.jpg);
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			width: 100%;
			background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
		}
	}
	#drop-down-subnav-block{
		background: #eeeeee;
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
	}
	@include media(sp){
		#drop-down-subnav-block{
			width: auto;
			padding: 10px;
		}
	}
	#ir-drop-down-wrap{
		@include  flex(wrap,center,space-between);
		.ir-drop-down-block{
			width: 280px;
			margin-right: 2px;
			h3{
				background-repeat: no-repeat;
				background-position: center;
				color: #fff;
				position: relative;
				padding: 25px 20px 25px 30px;
				margin: 0;
				font-size: 1.8rem;
				font-weight: 400;
				cursor: pointer;
				.drop-down-arr{
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -15px;
					width: 30px;
					height: 30px;
					border-radius: 30px;
					border: 1px solid #fff;
					display: block;
				}
				.drop-down-arr:after{
					content: '';
					display: block;
					height: 10px;
					width: 2px;
					background: #fff;
					position: absolute;
					top: 50%;
					right: 50%;
					margin: -5px -1px 0 0;
					@include trans;
				}
				.drop-down-arr:before{
					content: '';
					display: block;
					width: 10px;
					height: 2px;
					background: #fff;
					position: absolute;
					top: 50%;
					right: 50%;
					margin: -1px -5px 0 0;
				}
			}
		}
		.ir-drop-down-block.dd-open{
			h3{
				.drop-down-arr{
				}
				.drop-down-arr:after{
					height: 0;
				}
			}
		}
		.ir-drop-down-block:last-child{
			margin: 0;
		}
		.ir-drop-down-list{
			padding: 30px 20px;
			display: none;
			background: #fff;
			ul{
				padding: 0;
				margin: 0;
				list-style: none;
				li{
					margin-top: 20px;
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						font-size: 1.5rem;
						position: relative;
						padding: 0 0 0 20px;
						color: #292929;
						.drop-menu-icon{
							display: block;
							height: 1px;
							width: 10px;
							background: #d2a100;
							position: absolute;
							left: 0;
							top: 50%;
							margin-top: 0px;
						}
						.drop-menu-icon:before{
							content: "";
							width: 6px;
							height: 6px;
							display: block;
							background: #d2a100;
							border-radius: 6px;
							position: absolute;
							right: -5px;
							top: 50%;
							margin-top: -3px;
							@include trans;
						}
					}
					a:hover{
						opacity: 0.8;
						filter: alpha(opacity=80);
						.drop-menu-icon{
						}
						.drop-menu-icon:before{
							right: 10px;
						}
					}
				}
				li:first-child{
					margin-top: 0;
				}
			}
		}
	}
	@include media(sp){
		#ir-drop-down-wrap{
			.ir-drop-down-block{
				width: 50%;
				width: calc(50% - 2px);;
				margin: 2px 0 0 0;
				h3{
					padding: 25px 20px 25px 20px;
					font-size: 1.5rem;
					.drop-down-arr{
					}
					.drop-down-arr:after{
					}
					.drop-down-arr:before{
					}
				}
			}
			.ir-drop-down-block.dd-open{
				h3{
					.drop-down-arr{
					}
					.drop-down-arr:after{
					}
				}
			}
			.ir-drop-down-block:last-child{
				margin: 2px 0 0 0;
			}
			.ir-drop-down-block:nth-child(even){
				margin-left: 2px;
			}
			.ir-drop-down-list{
				ul{
					li{
						a,
						a:link,
						a:visited,
						a:active{
							.drop-menu-icon{
							}
							.drop-menu-icon:before{
							}
						}
						a:hover{
							.drop-menu-icon{
							}
							.drop-menu-icon:before{
							}
						}
					}
					li:first-child{
					}
				}
			}
		}
	}
	#ir-sub-nav{
		@include  flex(wrap,center,center);
		-ms-flex-align: center;
		align-items: center;
		margin: 10px 0 0 0;
		a,
		a:link,
		a:visited,
		a:active{
			display: flex;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-align-content: center;
			-ms-flex-align: center;
			align-items: center;
			width: 260px;
			height: 80px;
			font-size: 1.5rem;
			position: relative;
			color: #014099;
			text-align: center;
			border-bottom: 1px solid #014099;
			background: #fff;
			margin: 0 10px 0 0;
			.drop-menu-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 15px;
				top: 50%;
				margin-top: 0px;
			}
			.drop-menu-icon:before{
				content: "";
				width: 6px;
				height: 6px;
				display: block;
				background: #d2a100;
				border-radius: 6px;
				position: absolute;
				right: -5px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
		}
		a:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			.drop-menu-icon{
			}
			.drop-menu-icon:before{
				right: 20px;
			}
		}
		a:last-child{
			margin: 0;
		}
	}
	@include media(sp){
		#ir-sub-nav{
			a,
			a:link,
			a:visited,
			a:active{
				width: calc(50% - 5px);;
				margin: 0 0 5px 0;
				font-size: 1.3rem;
				height: 60px;
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			a:nth-child(even){
				margin-left: 5px;
			}
			a:hover{
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			a:last-child{
			}
		}
	}
	.yearSelect{
		width: 1200px;
		margin: 30px auto 0 auto;
		padding: 0;
		background: #eeeeee;
		padding: 30px;
		select{
			-o-box-sizing: border-box;
			-ms-box-sizing: border-box;
			box-sizing: border-box;
			padding: 6px 10px;
			font-size: 1.4rem;
			line-height: 18px;
			border: 1px solid #ccc;
			border-radius: 4px;
			text-align: left;
			margin-right: 10px;
		}
		#xj-select-year_s{
			width: 33%;
			padding: 18px 20px 18px 20px;
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	@include media(sp){
		.yearSelect{
			width: auto;
			select{
			}
			#xj-select-year_s{
				width: 100%;
			}
		}
	}
	#xj-select-category{
		width: 1200px;
		margin: 0 auto 70px auto;
		padding: 0;
		background: #eeeeee;
		padding: 0 30px 30px 30px;
		#type{
			margin-bottom: 0;
			li{
				a,
				a:link,
				a:visited,
				a:active{
					display: flex;
					-webkit-justify-content: center;
					justify-content: center;
					-webkit-align-content: center;
					-ms-flex-align: center;
					align-items: center;
					height: 80px;
					font-size: 1.5rem;
					position: relative;
					color: #014099;
					text-align: center;
					border: 1px solid #fff;
					border-bottom: 1px solid #014099;
					background: #fff;
					margin: 0 10px 0 0;
				}
				a:after{
					content: "";
					display: block;
					height: 1px;
					width: 20px;
					background: #d2a100;
					position: absolute;
					right: 15px;
					top: 50%;
					margin-top: 0px;
				}
				a:before{
					content: "";
					width: 6px;
					height: 6px;
					display: block;
					background: #d2a100;
					border-radius: 6px;
					position: absolute;
					right: 14px;
					top: 50%;
					margin-top: -3px;
					@include trans;
				}
				a:hover{
					opacity: 0.8;
					filter: alpha(opacity=80);
				}
				a:hover:after{
				}
				a:hover:before{
					right: 32px;
				}
			}
			li:last-child{
				a,
				a:link,
				a:visited,
				a:active{
					margin-right: 0;
				}
			}
			li.active{
				a,
				a:link,
				a:visited,
				a:active{
					border-bottom: 3px solid #014099;
				}
			}
		}
	}
	@include media(sp){
		#xj-select-category{
			width: auto;
			padding: 30px 0;
			#type{
				li{
					a,
					a:link,
					a:visited,
					a:active{
						margin: 0;
						border-right: 1px solid #014099;
					}
					a:after{
						display: none;
					}
					a:before{
						display: none;
					}
					a:hover{
					}
					a:hover:after{
					}
					a:hover:before{
					}
				}
				li:last-child{
					a,
					a:link,
					a:visited,
					a:active{
					}
				}
				li.active{
					a,
					a:link,
					a:visited,
					a:active{
					}
				}
			}
		}
	}
	#ir-news{
		width: 960px;
		margin: 30px auto 70px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		.ir-more{
			position: absolute;
			top: 70px;
			right: 90px;
			font-size: 1.3rem;
			.drop-menu-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: -30px;
				top: 50%;
				margin-top: 0px;
			}
			.drop-menu-icon:before{
				content: "";
				width: 6px;
				height: 6px;
				display: block;
				background: #d2a100;
				border-radius: 6px;
				position: absolute;
				right: -5px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
		}
		.ir-more:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			.drop-menu-icon{
			}
			.drop-menu-icon:before{
				right: 15px;
			}
		}
		.ir-news-block{
			h2{
				margin: 0;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 2rem;
				padding-bottom: 16px;
				border-bottom: 2px solid #0cbb8c;
				span{
					font-size: 1.3rem;
					padding-left: 20px;
				}
			}
		}
		#xj-mainlist{
			dl{
				@include flex(wrap,start,space-between);
				margin: 0;
				padding-top: 25px;
				border: none;
				dt{
					font-size: 1.4rem;
					color: #777777;
					font-weight: 400;
				}
				dd{
					margin: 0;
				}
				.label{
					color: #fff;
					margin: 0 0 0 10px;
					padding: 2px 14px;
					font-size: 1.3rem;
					background: #019d85;
					width: auto;
				}
				.icon_legal{
					background: #019d85;
				}
				.icon_result{
					background: #e71648;
				}
				.icon_info{
					background: #e71648;
				}
				.txt{
					width: 100%;
					margin-top: 10px;
					padding-bottom: 25px;
					border-bottom: 1px solid #dddddd;
					position: relative;
				}
				.icon_blank{
					background-size: 12px 12px;
					padding-left: 1.5rem;
				}
				.icon_pdf{
					background-size: 12px 12px;
				}
				.icon_pdf:after{
					content: "PDF";
					display: block;
					position: absolute;
					right: -40px;
					top: 50%;
					font-size: 1rem;
					background: #014099;;
					padding: 0 3px;
					color: #fff;
					margin-top: -10px;
				}
				.label.icon_discro{background: #014099;}
				.label.icon_result{background: #e7174a;}
				.label.icon_legal{background: #009c84;}
				.label.icon_ir{background: #ee9200;}
				.label.icon_info{background: #33bde2;}
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: underline;
					position: relative;
				}
				a:hover{
				}
			}
		}
	}
	@include media(sp){
		#ir-news{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.ir-more{
				top: 55px;
				right: 70px;
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-more:hover{
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-news-block{
				h2{
					span{
					}
				}
			}
			#xj-mainlist{
				dl{
					dt{
					}
					dd{
					}
					.label{
						font-size: 1.2rem;
						padding: 2px 10px;
					}
					.icon_legal{
					}
					.icon_result{
					}
					.icon_info{
					}
					.txt{
					}
					.icon_pdf:after{
						top: auto;
						bottom: 3px;
						right: -30px;
					}
					.label.icon_discro{background: #014099;}
					.label.icon_result{background: #e7174a;}
					.label.icon_legal{background: #009c84;}
					.label.icon_ir{background: #ee9200;}
					.label.icon_info{background: #33bde2;}
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
				}
			}
		}
	}
	.ir-block{
		width: 960px;
		margin: 30px auto 70px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		.ir-more{
			position: absolute;
			top: 70px;
			right: 90px;
			font-size: 1.3rem;
			.drop-menu-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: -30px;
				top: 50%;
				margin-top: 0px;
			}
			.drop-menu-icon:before{
				content: "";
				width: 6px;
				height: 6px;
				display: block;
				background: #d2a100;
				border-radius: 6px;
				position: absolute;
				right: -5px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
		}
		.ir-more:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			.drop-menu-icon{
			}
			.drop-menu-icon:before{
				right: 15px;
			}
		}
		.ir-news-block{
			h2{
				margin: 0;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 2rem;
				padding-bottom: 16px;
				border-bottom: 2px solid #0cbb8c;
				span{
					font-size: 1.3rem;
					padding-left: 20px;
				}
			}
		}
		#xj-mainlist{
			dl{
				@include flex(wrap,start,space-between);
				margin: 0;
				padding-top: 25px;
				border: none;
				dt{
					font-size: 1.4rem;
					color: #777777;
					font-weight: 400;
				}
				dd{
					margin: 0;
				}
				.label{
					color: #fff;
					margin: 0 0 0 10px;
					padding: 2px 14px;
					font-size: 1.3rem;
					background: #019d85;
					width: auto;
				}
				.icon_legal{
					background: #019d85;
				}
				.icon_result{
					background: #e71648;
				}
				.icon_info{
					background: #e71648;
				}
				.txt{
					width: 100%;
					margin-top: 10px;
					padding-bottom: 25px;
					border-bottom: 1px solid #dddddd;
					position: relative;
				}
				.icon_blank{
					background-size: 12px 12px;
					padding-left: 1.5rem;
				}
				.icon_pdf{
					background-size: 12px 12px;
				}
				.icon_pdf:after{
					content: "PDF";
					display: block;
					position: absolute;
					right: -40px;
					top: 50%;
					font-size: 1rem;
					background: #014099;;
					padding: 0 3px;
					color: #fff;
					margin-top: -10px;
				}
				.label.icon_discro{background: #014099;}
				.label.icon_result{background: #e7174a;}
				.label.icon_legal{background: #009c84;}
				.label.icon_ir{background: #ee9200;}
				.label.icon_info{background: #33bde2;}
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: underline;
					position: relative;
				}
				a:hover{
				}
			}
		}
	}
	@include media(sp){
		.ir-block{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.ir-more{
				top: 55px;
				right: 70px;
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-more:hover{
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-news-block{
				h2{
					span{
					}
				}
			}
			#xj-mainlist{
				dl{
					dt{
					}
					dd{
					}
					.label{
						font-size: 1.2rem;
						padding: 2px 10px;
					}
					.icon_legal{
					}
					.icon_result{
					}
					.icon_info{
					}
					.txt{
					}
					.icon_pdf:after{
						top: auto;
						bottom: 3px;
						right: -30px;
					}
					.label.icon_discro{background: #014099;}
					.label.icon_result{background: #e7174a;}
					.label.icon_legal{background: #009c84;}
					.label.icon_ir{background: #ee9200;}
					.label.icon_info{background: #33bde2;}
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
				}
			}
		}
	}
	.adobe-pdf{
		width: 1200px;
		margin: 30px auto 50px auto;
		padding: 0;
		background: #eeeeee;
		padding: 30px;
		text-align: center;
		a,
		a:link,
		a:visited,
		a:active{
			text-decoration: underline;
		}
		a:hover{
			text-decoration: none;
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
	}
	@include media(sp){
		.adobe-pdf{
			width: auto;
			font-size: 1.4rem;
			a,
			a:link,
			a:visited,
			a:active{
			}
			a:hover{
			}
		}
	}

	// 役員プロフィール
	.board-member{
		width: 960px;
		margin: 0 auto;
		padding: 40px 10px;
		.board-member-block{
			display: flex;
			-ms-flex-align: stretch;
			align-items: stretch;
		}
		.board-member-name{
			@include  flex(wrap,center,center);
			-ms-flex-align: center;
			align-items: center;
			border-bottom: 1px solid #014099;
			border-top: 1px solid #014099;
			padding: 30px 20px;
		}
		h2{
			font-size: 1.6rem;
			font-weight: 600;
			width: 200px;
			color: #18384c;
			margin: 0;
			span{
				display: block;
				font-size: 2rem;
				font-weight: 600;
				color: #014099;
			}
		}
		.board-member-history{
			font-weight: 300;
			width: 760px;
			border-bottom: 1px solid #dcdcdc;
			border-top: 1px solid #dcdcdc;
			padding: 30px 20px;
			dl{
				margin: 0;
				@include  flex(wrap,center,center);
				dt{
					font-size: 1.4rem;
					font-weight: 400;
					width: 15%;
					padding: 5px 0;
				}
				dd{
					margin: 0;
					font-size: 1.4rem;
					font-weight: 400;
					width: 85%;
					padding: 5px 0;
				}
			}
		}
	}
	.board-member:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
		margin-bottom: 15px;
	}
	@include media(sp){
		.board-member{
			width: auto;
			.board-member-block{
				display: block;
			}
			.board-member-name{
				display: block;
				border-bottom: none;
				border-top: 1px solid #014099;
				padding: 20px 10px;
			}
			h2{
				font-size: 1.6rem;
				font-weight: 600;
				width: auto;
				span{
					font-size: 2rem;
				}
			}
			.board-member-history{
				width: auto;
				padding: 10px 10px;
				dl{
					margin: 0;
					display: block;
					dt{
						font-weight: 600;
						width: auto;
					}
					dd{
						margin: 0;
						width: auto;
					}
				}
			}
		}
		.board-member:before{
			content: " ";
			display: block;
			width: 5px;
			height: 5px;
			background: #014099;
			margin-bottom: 15px;
		}
	}



	// table表組み
	.ir-table{
		width: 960px;
		margin: 0 auto;
		padding: 40px 10px;
		.ir-table-block{
			display: flex;
			-ms-flex-align: stretch;
			align-items: stretch;
		}
		.ir-table-name{
			border-top: 1px solid #014099;
			padding: 30px 20px;
		}
		h2{
			font-size: 1.6rem;
			font-weight: 600;
			width: 200px;
			color: #18384c;
			margin: 0;
		}
		.ir-table-sec{
			font-weight: 300;
			width: 760px;
			border-top: 1px solid #dcdcdc;
			padding: 30px 20px;
			font-size: 1.4rem;
			font-weight: 400;
		}
		.ir-table-block:last-child{
			.ir-table-name{
			border-bottom: 1px solid #014099;
			}
			.ir-table-sec{
			border-bottom: 1px solid #dcdcdc;
			}
		}
	}
	.ir-table.clm3{
		.ir-table-name{
			width: 300px;
		}
		.ir-table-sec{
			width: 330px;
		}
		.ir-table-block:last-child{
			.ir-table-name{
			}
			.ir-table-sec{
			}
		}
	}
	.ir-table:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
		margin-bottom: 15px;
	}
	@include media(sp){
		.ir-table{
			width: auto;
			.ir-table-block{
				display: block;
			}
			.ir-table-name{
				display: block;
				border-bottom: none;
				border-top: 1px solid #014099;
				padding: 20px 10px 0 10px;
			}
			h2{
				font-size: 1.6rem;
				font-weight: 600;
				width: auto;
				span{
					font-size: 2rem;
				}
			}
			.ir-table-sec{
				width: auto;
				padding: 10px 10px;
				border: none;
			}
			.ir-table-block:last-child{
				.ir-table-name{
				border-bottom: 1px solid #014099;
				}
				.ir-table-sec{
				border-bottom: 1px solid #014099;
				}
			}
		}
		.ir-table.clm3{
			.ir-table-name{
			}
			h2{
				width: auto;
			}
			.ir-table-sec{
				width: auto;
			}
			.ir-table-block:last-child{
				.ir-table-name{
				}
				.ir-table-sec{
				}
			}
		}
		.ir-table:before{
			content: " ";
			display: block;
			width: 5px;
			height: 5px;
			background: #014099;
			margin-bottom: 15px;
		}
	}

}


body.common.page-template-page-ir-basic{
	.ir-main-area{
		padding: 0 10px;
	}
	.free-area{
		width: 960px;
		margin: 0 auto 50px auto;
		padding: 50px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		a,
		a:link,
		a:visited,
		a:active{
			text-decoration: underline;
			position: relative;
			color: #014099;
		}
		a:hover{
			text-decoration: none;
		}
    	h1,
    	h2,
    	h3,
    	h4,
    	h5{
			font-size: 2.4rem;
			font-weight: 600;
			padding: 15px 0;
			margin: 0;
    	}
    	h3{
			font-size: 2.2rem;
    	}
    	h4{
			font-size: 2rem;
    	}
    	h5{
			font-size: 1.8rem;
    	}
	}

	.ir-3clumn-block{
		width: 1200px;
		margin: 0 auto 50px auto;
		padding: 50px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		@include  flex(wrap,left,center);
		// -ms-flex-align: center;
		// align-items: center;
		.ir-3clumn{
			width: 33.3333333333%;
			padding: 10px 20px;
	    	h1,
	    	h2,
	    	h3,
	    	h4,
	    	h5{
				font-size: 2rem;
				font-weight: 500;
				padding: 15px 0;
				margin: 0;
				color: #014099;
	    	}
	    	h3{
				font-size: 1.8rem;
	    	}
	    	h4{
				font-size: 1.6rem;
	    	}
	    	h5{
				font-size: 1.5rem;
	    	}
	    	p{
	    		margin: 0;
	    	}
		}

		table{
			margin: 0;
			thead{
				tr{
					th,td{
					}
				}
			}
			tbody{
				tr{
					th,td{
						font-size: 1.2rem;
					}
					th{
					}
					td{

					}
				}
			}
		}
	}
	.ir-notice-xj{
		width: 960px;
		margin: 0 auto 50px auto;
		padding: 50px;
		border: 1px solid #dddddd;
		background: #fff;
	}
	.disclosure-block{
		width: 960px;
		margin: 0 auto 50px auto;
		padding: 50px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
		box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
    	h1,
    	h2,
    	h3,
    	h4,
    	h5{
			font-size: 2rem;
			font-weight: 600;
			padding: 15px 0;
			margin: 0;
			color: #014099;
    	}
    	h3{
			font-size: 1.8rem;
    	}
    	h4{
			font-size: 1.6rem;
    	}
    	h5{
			font-size: 1.5rem;
    	}
    	p{
    		margin: 0;
    	}
		h2{
			padding-top: 30px;
		}
		.disclosure-wrap{
			border-bottom: 1px solid #dddddd;
		}
		.disclosure-wrap:last-child{
			border: none;
		}
		.disclosure-wrap:first-child{
			h2{
				padding-top: 0;
			}
		}
		.disclosure-txt{
			margin-bottom: 30px;

		}
	}

	.cell-block{
		width: 960px;
		margin: 0 auto;
		padding: 40px 10px;
		h2{
			font-size: 2.4rem;
			font-weight: 300;
			padding: 15px 0;
			margin: 0;
		}
		.cell-block-content{
			width: 700px;
			margin: 0 auto;
			dl{
				margin: 0;
				@include flex(nowrap,center,center);
				dt{
					font-size: 1.4rem;
					font-weight: 400;
					padding: 20px 30px;
					width: 30%;
					background: #d4f0f8;
					margin: 2px;
				}
				dd{
					font-size: 1.4rem;
					font-weight: 400;
					padding: 20px 30px;
					width: 70%;
					background: #f0f4f9;
					margin: 2px;
				}
			}
			dl:nth-child(even){
				dt{

				}
				dd{
					background: #fff;
					border: 1px solid #f0f4f9;
				}
			}
		}
	}
	.cell-block:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
	}
	@include media(sp){
		.cell-block{
			width: auto;
			h2{
				font-size: 2.4rem;
				font-weight: 300;
				padding: 15px 0;
				margin: 0;
			}
			.cell-block-content{
				width: auto;
				margin: 0 auto;
				dl{
					margin: 0;
					dt{
						font-weight: 600;
						padding: 20px;
						background: #d4f0f8;
						width: 40%;
					}
					dd{
						font-weight: 400;
						padding: 20px;
						background: #f0f4f9;
						width: 60%;
					}
				}
				dl:nth-child(even){
					dt{

					}
					dd{
					}
				}
			}
		}
		.cell-block:before{
			content: " ";
			display: block;
			width: 5px;
			height: 5px;
			background: #014099;
		}
	}
}
@include media(sp){

	body.common.page-template-page-ir-basic{
		.free-area{
			width: auto;
			margin: 0 auto 50px auto;
			padding: 30px;
			-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			a,
			a:link,
			a:visited,
			a:active{
			}
			a:hover{
			}
	    	h1,
	    	h2,
	    	h3,
	    	h4,
	    	h5{
	    	}
	    	h3{
	    	}
	    	h4{
	    	}
	    	h5{
	    	}
		}

		.ir-3clumn-block{
			width: auto;
			padding: 30px;
			.ir-3clumn{
				width: auto;
				padding: 10px 20px;
		    	h1,
		    	h2,
		    	h3,
		    	h4,
		    	h5{
		    	}
		    	h3{
		    	}
		    	h4{
		    	}
		    	h5{
		    	}
		    	p{
		    	}
			}

			table{
				thead{
					tr{
						th,td{
						}
					}
				}
				tbody{
					tr{
						th,td{
						}
						th{
						}
						td{

						}
					}
				}
			}
		}
		.ir-notice-xj{
			width: auto;
			padding: 30px;
		}
		.disclosure-block{
			width: auto;
			padding: 30px;
	    	h1,
	    	h2,
	    	h3,
	    	h4,
	    	h5{
	    	}
	    	h3{
	    	}
	    	h4{
	    	}
	    	h5{
	    	}
	    	p{
	    	}
			h2{
			}
			.disclosure-wrap{
			}
			.disclosure-wrap:last-child{
			}
			.disclosure-wrap:first-child{
				h2{
				}
			}
			.disclosure-txt{

			}
		}
	}
}



body.common.page-template-page-ir-library{

	.ir-library{
		width: 960px;
		margin: 30px auto 70px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		.ir-more{
			position: absolute;
			top: 70px;
			right: 90px;
			font-size: 1.3rem;
			.drop-menu-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: -30px;
				top: 50%;
				margin-top: 0px;
			}
			.drop-menu-icon:before{
				content: "";
				width: 6px;
				height: 6px;
				display: block;
				background: #d2a100;
				border-radius: 6px;
				position: absolute;
				right: -5px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
		}
		.ir-more:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			.drop-menu-icon{
			}
			.drop-menu-icon:before{
				right: 15px;
			}
		}
		h2{
			margin: 0;
			font-family: 'Asap',"Noto Sans JP";
			font-size: 2rem;
			padding-bottom: 16px;
			border-bottom: 2px solid #0cbb8c;
			span{
				font-size: 1.3rem;
				padding-left: 20px;
			}
		}

		.link-button{
			a,
			a:link,
			a:visited,
			a:active{
				display: block;
				background: #014099;
				color: #fff;
				margin: 0 auto 0 auto;
				border-bottom: 1px solid #014099;
				text-align: center;
				width: 240px;
				padding: 15px 10px;
				font-size: 1.4rem;
				position: relative;
			}
			a:after{
				content: "";
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: 15px;
				top: 50%;
				margin-top: 0px;
			}
			a:before{
				content: "";
				width: 7px;
				height: 7px;
				display: block;
				background: #d2a100;
				border-radius: 5px;
				position: absolute;
				right: 10px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
			a:hover{
				color: #014099;
				background: #fff;
			}
			a:hover:before{
				right: 30px;
			}
		}
		#xj-mainlist-results,
		#xj-mainlist-presen,
		#xj-mainlist-securities,
		#xj-mainlist-meeting,
		#xj-mainlist-disclosure{
			dl{
				@include flex(wrap,start,space-between);
				margin: 0;
				padding-top: 25px;
				border: none;
				dt{
					font-size: 1.4rem;
					color: #777777;
					font-weight: 400;
				}
				dd{
					margin: 0;
				}
				.label{
					color: #fff;
					margin: 0 0 0 10px;
					padding: 2px 14px;
					font-size: 1.3rem;
					background: #019d85;
					width: auto;
				}
				.icon_legal{
					background: #019d85;
				}
				.icon_result{
					background: #e71648;
				}
				.icon_info{
					background: #e71648;
				}
				.txt{
					width: 100%;
					margin-top: 10px;
					padding-bottom: 25px;
					border-bottom: 1px solid #dddddd;
					position: relative;
				}
				.icon_blank{
					background-size: 12px 12px;
					padding-left: 1.5rem;
				}
				.icon_pdf{
					background-size: 12px 12px;
				}
				.icon_pdf:after{
					content: "PDF";
					display: block;
					position: absolute;
					right: -40px;
					top: 50%;
					font-size: 1rem;
					background: #014099;;
					padding: 0 3px;
					color: #fff;
					margin-top: -10px;
				}
				.label.icon_discro{background: #014099;}
				.label.icon_result{background: #e7174a;}
				.label.icon_legal{background: #009c84;}
				.label.icon_ir{background: #ee9200;}
				.label.icon_info{background: #33bde2;}
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: underline;
					position: relative;
				}
				a:hover{
				}
			}
		}
	}
	@include media(sp){
		.ir-library{
			width: auto;
			margin: 50px 10px;
			padding: 50px 30px 40px 30px;
			.ir-more{
				top: 55px;
				right: 70px;
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-more:hover{
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			h2{
				span{
				}
			}
			#xj-mainlist-results,
			#xj-mainlist-presen,
			#xj-mainlist-securities,
			#xj-mainlist-meeting,
			#xj-mainlist-disclosure{
				dl{
					dt{
					}
					dd{
					}
					.label{
						font-size: 1.2rem;
						padding: 2px 10px;
					}
					.icon_legal{
					}
					.icon_result{
					}
					.icon_info{
					}
					.txt{
					}
					.icon_pdf:after{
						top: auto;
						bottom: 3px;
						right: -30px;
					}
					.label.icon_discro{background: #014099;}
					.label.icon_result{background: #e7174a;}
					.label.icon_legal{background: #009c84;}
					.label.icon_ir{background: #ee9200;}
					.label.icon_info{background: #33bde2;}
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
				}
			}
		}
	}
}

@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

