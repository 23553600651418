@charset "utf-8";
@import "_setting";
@import "import/_var";

@import "import/_archive.scss";
@import "import/_author.scss";
@import "import/_base.scss";
@import "import/_checkout.scss";
@import "import/_company.scss";
@import "import/_contact.scss";
@import "import/_default.scss";
@import "import/_footer.scss";
@import "import/_header.scss";
@import "import/_ir.scss";
@import "import/_main.scss";
@import "import/_map.scss";
@import "import/_product.scss";
@import "import/_recruit.scss";
@import "import/_single.scss";
@import "import/_support.scss";
@import "import/_template-page-common.scss";
@import "import/_template-page.scss";
@import "import/_var.scss";
html{
}
body.common{
}
@media screen and (min-width: 768px) {
	.pc{
	display: block!important;
	}
	.sp{
	display: none!important;
	}
}
@media screen and (max-width: 768px) {
	.pc{
		display: none!important;
	}
	.sp{
		display: block!important;
	}
body.common{

}

}


.inviewfadeIn {
  opacity: 0;
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s;
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
  -webkit-transition: all .8s linear;
  -o-transition: all .8s linear;
  transition: all .8s linear; }

.fadeIn {
  opacity: 1; }

.inviewzoomIn {
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform: scale(0); }

.inviewzoomIn,
.zoomIn {
  -webkit-transition: all .6s ease-in-out !important;
  -o-transition: all .6s ease-in-out !important;
  transition: all .6s ease-in-out !important; }

.zoomIn {
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform: scale(1); }

.inviewfadeInUp {
  opacity: 0;
  -ms-transform: translateY(2rem);
  transform: translateY(2rem);
  -webkit-transform: translateY(2rem); }

.fadeInUp,
.inviewfadeInUp {
  -webkit-transition: all .5s ease-in-out .2s !important;
  -o-transition: all .5s ease-in-out .2s !important;
  transition: all .5s ease-in-out .2s !important; }

.fadeInUp {
  opacity: 1;
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translate(0); }

.inviewfadeInDown {
  opacity: 0;
  -ms-transform: translateY(-7rem);
  transform: translateY(-7rem);
  -webkit-transform: translateY(-7rem);
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s; }

.fadeInDown {
  opacity: 1;
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translate(0); }

.inviewfadeInRight {
  opacity: 0;
  -ms-transform: translate(6rem);
  transform: translate(6rem);
  -webkit-transform: translate(6rem);
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s; }

.fadeInRight {
  opacity: 1;
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translate(0); }

.inviewfadeInLeft {
  opacity: 0;
  -ms-transform: translate(-1rem);
  transform: translate(-1rem);
  -webkit-transform: translate(-6rem);
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s; }

.fadeInLeft {
  opacity: 1;
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translate(0); }

.inviewfadeInLeft2 {
  opacity: 0 !important;
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg); }

.fadeInLeft2,
.inviewfadeInLeft2 {
  -webkit-transition: .6s;
  -o-transition: .6s;
  transition: .6s; }

.fadeInLeft2 {
  opacity: 1 !important;
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.inviewUp {
  -ms-transform: translateY(4rem);
  transform: translateY(4rem);
  -webkit-transform: translateY(4rem);
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s; }

.Up {
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translate(0); }

.inviewDown {
  -ms-transform: translateY(-4rem);
  transform: translateY(-4rem);
  -webkit-transform: translateY(-4rem);
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s; }

.Down {
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translate(0); }