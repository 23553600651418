body.common.single{
	header.sub-nav{
		background: #014099;
		h1{
			svg{
			}
		}
		@include media(sp){
			h1{
				svg{
				}
			}
		}
		.sub-nav-btn-wrap{
			.sub-nav-btn{
			}
			@include media(sp){
			}
			p{
			}
			.sub-nav-btn:before,
			.sub-nav-btn:after{
			}
			.sub-nav-btn:before{
			}
			.sub-nav-btn:after{
			}
		}
		.sub-nav-btn-wrap:hover{
		}
		.sub-nav-all{
			.sub-nav-all-wrap{
				.sub-nav-all-block{
					.sub-nav-all-menu-icon{
					}
					.sub-nav-all-menu-icon:before{
					}
					h4{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
							.sub-nav-all-menu-icon{
							}
							.sub-nav-all-menu-icon:before{
							}
						}
					}
					ul{
					}
					li{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
						}
						a:before{
					}
					}
				}
				.sub-nav-all-block:first-child{
				}
				@include media(sp){
					.sub-nav-all-block{
					}
				}
			}
			@include media(sp){
				.sub-nav-all-wrap{
				}
			}
		}
		.sub-nav-all:before{
		}
		@include media(sp){
			.sub-nav-all{
				.sub-nav-all-wrap{
				}
			}
		}
	}
	@include media(sp){
		header.sub-nav{
		}
	}

	#main-area{
		height: 240px;
		background: url(../img/no-img-news-header.jpg) no-repeat right;
		background-size: contain;
		margin-bottom: 50px;
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			width: 40%;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 50%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.7) 100%);
	}
	@include media(sp){
		#main-area{
			height: 50vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
				background-image: url(../img/no-img-news-header-sp.jpg);
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
				}
			}
		}
		#main-area:after{
			width: 100%;
			background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
		}
	}

	.news-block{
		@include  flex(wrap,space-between,space-between);
		width: 950px;
		margin: 0 auto 50px auto;
		#primary{
			width: 640px;
			article{
				padding: 40px;
				-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
				box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.25);
			}
			.post-meta{
				border-bottom: 1px solid #dcdcdc;
				padding: 0 0 19px 0;
				.entry-title{
					font-size: 2rem;
					font-weight: 400;
					margin: 0;
				}
				.post-day{
					margin-top: 15px;
					font-size: 1.4rem;
					font-weight: 400;
					display: block;
				}
			}
			.post-eye-catch{
				padding: 40px 0;
				text-align: center;
				img{
				}
			}
			.entry-content{
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: underline;
					color: #014099;
				}
				a:hover{
					text-decoration: none;
				}
				h2{
					font-size: 2.4rem;
					color: #19a8c2;
					font-weight: 600;
					margin: 10px 0;
				}
				h3{
					font-size: 1.8rem;
					color: #18384c;
					font-weight: 400;
					position: relative;
					padding: 0 0 10px 0;
					border-bottom: 1px solid #dcdcdc;
					margin: 10px 0;
				}
				h3:before {
					position: absolute;
					bottom: -1px;
					left: 0;
					width: 20%;
					height: 1px;
					content: '';
					background: #014099;
				}
				h4{
					font-size: 1.6rem;
					color: #18384c;
					font-weight: 300;
					margin: 10px 0;
				}
				h4:before{
					content: "";
					width: 10px;
					height: 10px;
					display: inline-block;
					background: #014099;
					border-radius: 10px;
					margin: 0 8px 0 0;
				}
			}
		}
		.entory-footer{
			@include  flex(wrap,center,space-between);
			-ms-flex-align: center;
			align-items: center;
			margin: 50px 0 0 0;
			padding: 30px 0 0 0;
			border-top: 1px solid #dcdcdc;
			a,
			a:link,
			a:visited,
			a:active{
				font-size: 1.4rem;
			}
			a:hover{
			}
		}
		.side-bar{
			width: 260px;
			border-top: 3px solid #014099;
			h2{
				font-size: 1.6rem;
				color: #014099;
				font-weight: 600;
				padding: 30px 0 30px 0;
				margin: 0;
			}
			h2:before{
				content: "";
				width: 13px;
				height: 13px;
				display: inline-block;
				background: #fff;
				border: 3px solid #014099;
				border-radius: 13px;
				margin: 0 8px 0 0;
			}
			.side-news-links{
				padding: 0 0 30px 0;
				border-bottom: 1px solid #dcdcdc;
				li{
					a,
					a:link,
					a:visited,
					a:active{
						font-size: 1.2rem;
						position: relative;
						margin: 16px 0 0 0;
						padding: 0 0 0 30px;
						display: block;
					}
					a:hover{
					}
					a:after{
						content: "";
						display: block;
						height: 1px;
						width: 10px;
						background: #d2a100;
						position: absolute;
						left: 15px;
						top: 10px;
						margin-top: 0px;
					}
					a:before{
						content: "";
						width: 7px;
						height: 7px;
						display: block;
						background: #d2a100;
						border-radius: 5px;
						position: absolute;
						left: 7px;
						top: 10px;
						margin-top: -3px;
						@include trans;
					}
					a:hover:before{
						left: 18px;
					}
				}
			}
			.side-cat-links{
				padding: 0;
				margin: 0;
				list-style: none;
				a,
				a:link,
				a:visited,
				a:active{
					display: inline-block;
					border: 2px solid #014099;
					background: #eef5ff;
					color: #014099;
					padding: 2px 16px;
					border-radius: 30px;
				}
				a:hover{
				}
			}
		}
	}

	@include media(sp){
		.news-block{
			width: auto;
			#primary{
				width: auto;
				padding: 10px;
				article{
					-moz-box-shadow: 0px 0px 10px 6px rgba(20, 133, 207, 0.1);
					-webkit-box-shadow: 0px 0px 10px 6px rgba(20, 133, 207, 0.1);
					-ms-box-shadow: 0px 0px 10px 6px rgba(20, 133, 207, 0.1);
					box-shadow: 0px 0px 10px 6px rgba(20, 133, 207, 0.1);
				}
				.post-meta{
					.entry-title{
						font-size: 2.8rem;
					}
					.post-day{
						font-size: 1.2rem;
					}
				}
				.post-eye-catch{
					img{
					}
				}
				.entry-content{
					a,
					a:link,
					a:visited,
					a:active{
						text-decoration: underline;
						color: #014099;
					}
					a:hover{
						text-decoration: none;
					}
					h2{
						font-size: 2rem;
					}
					h3{
						font-size: 1.6rem;
					}
					h3:before {
					}
					h4{
						font-size: 1.4rem;
						color: #18384c;
						font-weight: 300;
						margin: 10px 0;
					}
					h4:before{
					}
				}
			}
			.entory-footer{
				padding-bottom: 50px;
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
				}
			}
			.side-bar{
				width: auto;
				padding: 10px 20px;
				h2{
				}
				h2:before{
				}
				.side-news-links{
					li{
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
						}
						a:after{
						}
						a:before{
						}
						a:hover:before{
						}
					}
				}
				.side-cat-links{
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
				}
			}
		}
	}
}


@include media(sp){

}

