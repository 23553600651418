body.common.page-template-page-contact{

	header.sub-nav{
		background: #014099;
	}

	#main-area{
		height: 240px;
		background-image: url(../img/company/company-top-header.jpg);
		background-repeat: no-repeat;
		background-position: center right;
		background-size: cover;
		#main-area-wrap{
			background-image: auto;
		}
		.main-area-ttl{
			position: absolute;
			top: 50%;
			left: 40px;
			-ms-transform: translate(0%, -50%);
			-webkit-transform: translate(0%, -50%);
			transform: translate(0%, -50%);
			z-index: 10;
			h1{
				color: #014099;
				font-size: 2.8rem;
				font-weight: 500;
			}
			p{
				font-weight: 300;
				font-size: 1.5rem;
				width: 400px;
			}
		}
	}
	#main-area:after{
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		overflow:hidden;
		background-image: linear-gradient(90deg, rgb(255, 255, 255) 40%,rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
	}
	@include media(sp){
		#main-area{
			height: 70vh;
			padding: 0;
			background-size: cover;
			background-image: none !important;
			#main-area-wrap{
				height: 70vh;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 10px;
			}
			.main-area-ttl{
				width: 100%;
				padding: 0 10px 50px 10px;
			    position: absolute;
				top: auto;
				left: 0px;
				-ms-transform: translate(0%, 0);
				-webkit-transform: translate(0%, 0);
				transform: translate(0%, 0);
				z-index: 10;
				bottom: 0;
				h1{
					color: #014099;
					font-size: 2.8rem;
					font-weight: 500;
				}
				p{
					font-weight: 500;
					font-size: 1.3rem;
					width: auto;
					margin: 0;
				}
			}
		}
		#main-area:after{
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			overflow:hidden;
			width: 100%;
			background-image: linear-gradient(0deg, white 30%, rgba(255, 255, 255, 0) 100%);
		}
	}
	#contact-form-up-block{
    	padding: 80px 10px;
		width: 960px;
		margin: 0 auto;
    	h1,
    	h2,
    	h3,
    	h4,
    	h5{
			font-size: 2.4rem;
			font-weight: 300;
			padding: 15px 0;
			margin: 0;
    	}
    	h3{
			font-size: 2.2rem;
    	}
    	h4{
			font-size: 2rem;
    	}
    	h5{
			font-size: 1.8rem;
    	}
	}
	#contact-form-up-block:before{
		content: " ";
		display: block;
		width: 5px;
		height: 5px;
		background: #014099;
	}
	@include media(sp){
		#contact-form-up-block{
			width: auto;
			padding: 30px 10px;
	    	h1,
	    	h2,
	    	h3,
	    	h4,
	    	h5{
	    	}
	    	h3{
	    	}
	    	h4{
	    	}
	    	h5{
	    	}
		}
		#contact-form-up-block:before{
		}
	}
	#primary{
		width: 960px;
		margin: 20px auto;
		.entry-content{
			-moz-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			-webkit-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			-ms-box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			box-shadow: 0px 0px 20px 10px rgba(20, 133, 207, 0.10);
			padding: 55px 60px;
	    	h1,
	    	h2,
	    	h3,
	    	h4,
	    	h5{
				font-size: 2.4rem;
				font-weight: 300;
				padding: 15px 0;
				margin: 0;
	    	}
	    	h3{
				font-size: 2.2rem;
	    	}
	    	h4{
				font-size: 2rem;
	    	}
	    	h5{
				font-size: 1.8rem;
	    	}
	    	#privacy-policy{
	    		border: 1px solid #cbcbcb;
	    		padding: 30px 40px;
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: underline;
					color: #d2a100;
				}
				a:hover{
					text-decoration: none;
				}
				.wpcf7-checkbox{
					padding: 30px 0 0 0;
					display: block;
					text-align: center;
				}
				.wpcf7-not-valid-tip{
					padding-top: 10px;
					text-align: center;
					font-weight: 600;
				}
				h1,
				h2,
				h3,
				h4,
				h5{
					padding-top: 0;
				}
	    	}
			@include media(sp){
		    	#privacy-policy{
		    		padding: 20px 20px;
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
					.wpcf7-checkbox{
					}
					.wpcf7-not-valid-tip{
					}
					h1,
					h2,
					h3,
					h4,
					h5{
					}
		    	}
			}
	    	#total-price{
	    		@include flex(wrap,right,space-between);
	    		margin: 30px auto;
	    		padding: 0 10px;
	    		span{
	    			font-size: 1.6rem;
	    			font-weight: 600;
	    		}
	    		#price-tani{
	    			
	    		}
	    		#price{
	    			
	    		}
	    		#price-option{
	    			
	    		}
	    	}
		}
	}
	@include media(sp){
		#primary{
			width: auto;
			padding: 0 10px;
			.entry-content{
				padding: 30px 20px;
		    	h1,
		    	h2,
		    	h3,
		    	h4,
		    	h5{
		    	}
		    	h3{
		    	}
		    	h4{
		    	}
		    	h5{
		    	}
		    	#privacy-policy{
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
					.wpcf7-checkbox{
					}
					.wpcf7-not-valid-tip{
					}
					h1,
					h2,
					h3,
					h4,
					h5{
					}
		    	}
		    	#total-price{
		    		span{
		    		}
		    		#price-tani{
		    			
		    		}
		    		#price{
		    			
		    		}
		    		#price-option{
		    			
		    		}
		    	}
			}
		}
	}

	table{
		margin: 0 auto 20px auto;
		thead{
			tr{
				border-top: 1px solid #cbcbcb;
				border-bottom: 1px solid #cbcbcb;
				th,td{
					font-weight: 600;
					text-align: left;
					padding: 20px 10px;
					font-size: 1.4rem;
				}
			}
		}
		tbody{
			tr{
				border-bottom: 1px solid #cbcbcb;
				th,td{
					padding: 20px 10px;
					text-align: left;
					.ttl-caption{
						font-size: 1.2rem;
						padding-left: 10px;
					}
					p{
						margin: 0;
						padding: 0;
					}
					br{
						display: none;
					}
				}
				th{
					font-size: 1.4rem;
					font-weight: 400;
					position: relative;
					padding-right: 50px;
				}
				td{

				}
			}
			.birthday{
				select,
				.short{
					width: 15rem;
				}
				span{
					margin-left: 20px;
				}
				span:first-child{
					margin-left: 0;
				}
			}
			tr:first-child{
				border-top: 1px solid #cbcbcb;
			}
			.must th:after{
				content: "必須";
				display: block;
				font-size: 1rem;
				background: #fff;
				color: #cd1a59;
				padding: 2px 6px;
				position: absolute;
				border: 2px solid #cd1a59;
				border-radius: 20px;
				top: 50%;
				right: 10px;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
			.adress-detail{
				display: block;
				font-size: 1.4rem;
				padding: 20px 0 10px 0;
			}
			#zip{
				width: 15rem;
			}
			#pref{
				width: 15rem;
			}
			.expendables-img{
				img{
					width: 100px;
				}
			}
			.size-b6,
			.size-b5,
			.size-a4,
			.oki,
			.epson{
				.cat-icon{
					font-size: 1.2rem;
					background: #000;
					color: #fff;
					border-radius: 4px;
					display: inline-block;
					margin: 0 10px 0 0;
					padding: 2px 6px;
				}
			}
			.inquiry-example{
				font-size: 1.4rem;
				color: #9c9c9c;
			}
		}
	}
	table.column2{
		tr{
			th{
				width: 30%;
			}
			td{
				width: 70%;
			}
		}
	}
	table.column3{
		tr.expendables-thum{
			th{
				width: 40%;

			}
			th.expendables-img{
				width: 20%;
			}
		}
		tr{
			th{
				// width: 60%;
			}
			td{
				width: 20%;
			}
		}
	}
	.submit{
		p{
			margin: 0;
		}
	}
	input[type=email],
	input[type=number],
	input[type=password],
	input[type=tel],
	input[type=text],{
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		padding: 10px;
		font-size: 1.4rem;
		line-height: 18px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	select{
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
		padding: 6px 10px;
		font-size: 1.4rem;
		line-height: 18px;
		border: 1px solid #ccc;
		border-radius: 4px;
		text-align: left;
		margin-right: 10px;
	}
	input[type=submit]{
		display: block;
		font-weight: 700;
		line-height: 1.5;
		text-align: center;
		box-sizing: border-box;
		cursor: pointer;
		border-radius: 0;
		width: 350px;
		margin: 50px auto 0 auto;
		@include trans;
		font-weight: 400;
		font-size: 16px;
		font-size: 1.6rem;
		color: #fff;
		padding: 20px 80px;
		border: 1px solid rgba(0, 0, 0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
		position: relative;
		background: #014099;
	}
	input[type=submit]:after{
		content: " ";
		display: block;
		height: 1px;
		width: 20px;
		background: #d2a100;
		position: absolute;
		right: 10px;
		top: 50%;
		margin-top: -5px;
	}
	input[type=submit]:before{
		content: " ";
		width: 7px;
		height: 7px;
		display: block;
		background: #d2a100;
		border-radius: 5px;
		position: absolute;
		right: -7px;
		top: 50%;
		margin-top: -4px;
		@include trans;
	}
	input[type=submit]:hover{
		opacity: 0.8;
		filter: alpha(opacity=80);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
	}
	@include media(sp){
		table{
			display: block;
			width: 100%;
			margin: 0 auto;
			thead{
				display: none;
				width: 100%;
				tr{
					th,td{
					}
				}
			}
			tbody{
				display: block;
				width: 100%;
				tr{
					display: block;
					border-bottom: 1px solid #cbcbcb;
					width: 100%;
					th,td{
						display: block;
						padding: 20px 10px 0 10px;
						width: 100%;
						.ttl-caption{
						}
					}
					th{
					}
					td{
					}
					td:last-child{
						padding-bottom: 20px;
					}
				}
				.birthday{
					select,
					.short{
						width: 80%;
						margin-right: 10px;
					}
					span{
						padding-bottom: 10px;
						margin-left: 0;
					}
					span:first-child{
						margin-left: 0;
					}
				}
				tr:first-child{
				}
				.must th{
					padding-left: 50px;
				}
				.must th:after{
					font-size: 1rem;
					-ms-transform: translate(0, 0);
					-webkit-transform: translate(0, 0);
					transform: translate(0,0);
					right: auto;
					left: 5px;
				}
				.adress-detail{
				}
				#zip{
					width: 100%;
				}
				#pref{
					width: 100%;
				}
				.expendables-img{
					img{
					}
				}
				.size-b6,
				.size-b5,
				.size-a4,
				.oki,
				.epson{
					.cat-icon{
						display: inline;
						margin-right: 10px;
					}
				}
			}
		}
		table.column2{
			tr{
				th{
					width: 100%;
				}
				td{
					width: 100%;
				}
			}
		}
		table.column3{
			tr.expendables-thum{
				th{
					width: 100%;

				}
				th.expendables-img{
					width: 100%;
				}
			}
			tr{
				th{
					// width: 60%;
				}
				td{
					width: 100%;
				}
			}
		}
		.submit{
			padding: 0 10px;
		}
		input[type=email],
		input[type=number],
		input[type=password],
		input[type=tel],
		input[type=text] {
			-o-box-sizing: border-box;
			-ms-box-sizing: border-box;
			box-sizing: border-box;
			width: 100%;
			padding: 10px;
			font-size: 16px;
			line-height: 18px;
			border: 1px solid #ccc;
		}
		input[type=submit]{
			display: block;
			position: relative;
			font-weight: 700;
			line-height: 1.5;
			text-align: center;
			box-sizing: border-box;
			cursor: pointer;
			border-radius: 0;
			width: 100%;
			margin: 50px auto 0 auto;
			@include trans;

			font-size: 16px;
			font-size: 1.6rem;
			color: #fff;
			padding: 20px 80px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
			border: 1px solid rgba(0, 0, 0, 0);
			position: relative;
			opacity: 1;
			filter: alpha(opacity=100);

			background: #cbcbcb;
		}
		input[type=submit]:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
		}

	}

	.inquiry-btn-hub{
		width: 960px;
		margin: 20px auto;
		@include flex(nowrap,center,space-between);
		a,
		a:link,
		a:visited,
		a:active{
			display: block;
			background: #014099;
			color: #fff;
			margin: 0 auto 0 auto;
			border-bottom: 1px solid #014099;
			text-align: center;
			width: 100%;
			padding: 15px 10px;
			margin: 0 10px;
			font-size: 1.4rem;
			position: relative;
		}
		a:after{
			content: "";
			display: block;
			height: 1px;
			width: 20px;
			background: #d2a100;
			position: absolute;
			right: 15px;
			top: 50%;
			margin-top: 0px;
		}
		a:before{
			content: "";
			width: 7px;
			height: 7px;
			display: block;
			background: #d2a100;
			border-radius: 5px;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -3px;
			@include trans;
		}
		a:hover{
			color: #014099;
			background: #fff;
		}
		a:hover:before{
			right: 30px;
		}
	}
}
@include media(sp){

}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

